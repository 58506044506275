import React, { useState, useEffect } from 'react';
import axios from '../axios.js'; 
import { useNavigate, NavLink } from 'react-router-dom';
import { useMessage } from '../MessageContext';
import { useTranslation } from 'react-i18next';

import ForgotPasswordModal from './ForgotPasswordModal';
import ResendConfirmationModal from './ResendConfirmationModal';

const Login = () => {
  const [backgroundImage, setBackgroundImage] = useState('');
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [showResendModal, setShowResendModal] = useState(false);
  const [offsetY, setOffsetY] = useState(0);
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const { email, password } = formData;
  const navigate = useNavigate();
  const { setMessage } = useMessage();

  useEffect(() => {
    const totalImages = 7;
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const imagePath = `${process.env.PUBLIC_URL}/images/backgrounds/nextrust${randomIndex}.jpg`;
    setBackgroundImage(imagePath);

    const handleScroll = () => setOffsetY(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    setMessage(t('Attempting to log you in...'));

    localStorage.removeItem('token');
    localStorage.removeItem('userId');

    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const body = JSON.stringify({ email, password });
      const { data } = await axios.post('/users/login', body, config);

      localStorage.setItem('token', data.token);
      localStorage.setItem('userId', data.user.id);

      setMessage(t('Login successful! Welcome back! Redirecting you to your dashboard...'));
      navigate('/nextrust/');
    } catch (err) {
      if (err.response) {
        setMessage(t('Login faied. Please check your credentials and try again.'));
      } else if (err.request) {
        setMessage(t('Login failed: No response from server. Please check your network connection and try again.'));
      } else {
        setMessage(t('Login failed. Please try again later.'));
      }
    }
  };

  const handleOpenForgotPasswordModal = () => {
    setShowForgotPasswordModal(true);
  };

  const handleCloseForgotPasswordModal = () => {
    setShowForgotPasswordModal(false);
  };

  const handleOpenResendModal = () => {
    setShowResendModal(true);
  };

  const handleCloseResendModal = () => {
    setShowResendModal(false);
  };

  return (
    <>
      <div className="nextrust-starting-page" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="overlay-content" style={{ top: `${offsetY * 0.3}px` }}>
          <form onSubmit={e => onSubmit(e)}>
            <h2>{t('Login to Your NexTrust Account')}</h2>
            <p><br></br></p>
            <div className="form-group">
              <label>{t('email')}</label>
              <input
                type="email"
                name="email"
                value={email}
                onChange={e => onChange(e)}
                required
              />
            </div>
            <div className="form-group">
              <label>{t('password')}</label>
              <input
                type="password"
                name="password"
                value={password}
                onChange={e => onChange(e)}
                required
              />
            </div>
            <button type="submit">{t('login')}</button>
            <div className="nav-links-container">
              <NavLink to="/register">{t('register_new_account')}</NavLink>
              <button onClick={handleOpenForgotPasswordModal}>{t('forgot_password')}</button>
              <button onClick={handleOpenResendModal}>{t('resend_confirmation_email')}</button>
            </div>
          </form>
        </div>
      </div>
      {showResendModal && <ResendConfirmationModal onClose={() => setShowResendModal(false)} />}
      {showForgotPasswordModal && <ForgotPasswordModal onClose={() => setShowForgotPasswordModal(false)} />}
    </>
  );
};

export default Login;
