import React, { useState, useEffect } from 'react';
import axiosInstance from '../axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { useMessage } from '../MessageContext'; 
import { useTranslation } from 'react-i18next';

function UpdateUser() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [passwordRequirements, setPasswordRequirements] = useState({
    minLength: false,
    upperCase: false,
    lowerCase: false,
    number: false,
    symbol: false,
    passwordsMatch: false
  });
  const { setMessage } = useMessage(); 
  const userId = localStorage.getItem('userId');
  const [initialEmail, setInitialEmail] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (!userId) {
      setMessage('Please log in again.'); 
      return;
    }

    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get(`/users/fetch/${userId}`);
        if (response.data) {
          setFormData({
            name: response.data.name || '',
            email: response.data.email || '',
            password: '',
            confirmPassword: ''
          });
          setInitialEmail(response.data.email || ''); 
        }
      } catch (error) {
		      setMessage(t('Failed to fetch user data.'));

      }
    };

    fetchUserData();
  }, [userId]);

  const validatePassword = (password, confirmPassword) => {
    const requirements = {
      minLength: password.length >= 8,
      upperCase: /[A-Z]/.test(password),
      lowerCase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      symbol: /[^A-Za-z0-9]/.test(password),
      passwordsMatch: password === confirmPassword
    };
    setPasswordRequirements(requirements);
    return Object.values(requirements).every(Boolean);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => {
      const updatedFormData = { ...prev, [name]: value };
      if (name === 'password' || name === 'confirmPassword') {
        validatePassword(updatedFormData.password, updatedFormData.confirmPassword);
      }
      return updatedFormData;
    });
  };

const handleSubmit = async (e) => {
  e.preventDefault();
  if (!validatePassword(formData.password, formData.confirmPassword)) {
    setMessage(t('Please ensure your new password meets all the requirements.'));
    return;
  }
  const { name, email, password } = formData;
  const updateData = { name, email, ...(password && { password }) };

  try {
    const response = await axiosInstance.put(`/users/update/${userId}`, updateData);
    if (email !== initialEmail) {
      setMessage(t('Email update is pending. Please confirm the change by clicking on the link sent to your new email address within the next hour.'));
    } else {
      setMessage(t('User updated successfully!'));
    }
    setFormData(prev => ({ ...prev, password: '', confirmPassword: '' }));
  } catch (error) {
    setMessage(t('Failed to update user.'));
  }
};

  const formatRequirement = (flag, text) => flag ? <span style={{ color: '#081a44' }}>{text}</span> : <span style={{ color: 'red' }}>{text}</span>;

  return (
    <div>
      <h1>{t('Update Your Details')}</h1>
      <form onSubmit={handleSubmit} className="update-form">
       <div className="form-field">
  <FontAwesomeIcon icon={faUser} className="icon"/>
  <input type="text" placeholder={t('name')} name="name" value={formData.name} onChange={handleChange} />
</div>
<div className="form-field">
  <FontAwesomeIcon icon={faEnvelope} className="icon"/>
  <input type="email" placeholder={t('email')} name="email" value={formData.email} onChange={handleChange} />
</div>
<div className="form-field">
  <FontAwesomeIcon icon={faLock} className="icon"/>
  <input type="password" placeholder={t('New Password (optional)')} name="password" value={formData.password} onChange={handleChange} />
</div>
<div className="form-field">
  <FontAwesomeIcon icon={faLock} className="icon"/>
  <input type="password" placeholder={t('confirm_password')} name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} />
</div>

        <div className="form-group">
           <p>{formatRequirement(passwordRequirements.minLength, t('At least 8 characters'))}</p>
<p>{formatRequirement(passwordRequirements.upperCase, t('An uppercase letter'))}</p>
<p>{formatRequirement(passwordRequirements.lowerCase, t('A lowercase letter'))}</p>
<p>{formatRequirement(passwordRequirements.number, t('A number'))}</p>
<p>{formatRequirement(passwordRequirements.symbol, t('A special character'))}</p>
<p>{formatRequirement(passwordRequirements.passwordsMatch, t('Passwords match'))}</p>
        </div>
        <button type="submit" className="update-button">{t('save_changes')}</button>
      </form>
    </div>
  );
}

export default UpdateUser;
