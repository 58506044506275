import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios'; // Ensure axiosInstance is correctly configured
import { useTranslation } from 'react-i18next';

const PresaleTokensRemaining = () => {
  const [totalTokensRemaining, setTotalTokensRemaining] = useState(0);
  const { t } = useTranslation();

useEffect(() => {
  axiosInstance.get('/token/fetchPresaleTokensRemaining')
    .then(response => {
      const totalTokensFormatted = new Intl.NumberFormat().format(response.data.totalTokensRemaining / (10 ** 18));
      setTotalTokensRemaining(totalTokensFormatted);
    })
    .catch(error => {
      console.error("Failed to fetch total tokens remaining. Error details:", error);
      console.error("Error status:", error.response?.status);
      console.error("Error data:", error.response?.data);
    });
}, []);


  return (
    <div>
      <h2>{t('Presale Tokens left:')} {totalTokensRemaining} {t('NXT')}</h2>
    </div>
  );
};

export default PresaleTokensRemaining;
