import React, { useState, useEffect } from 'react';
import axios from '../axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useMessage } from '../MessageContext';
import { useTranslation } from 'react-i18next';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordRequirements, setPasswordRequirements] = useState({
    minLength: false,
    upperCase: false,
    lowerCase: false,
    number: false,
    symbol: false,
    passwordsMatch: false
  });
  const [backgroundImage, setBackgroundImage] = useState('');
  const [offsetY, setOffsetY] = useState(0);
  const navigate = useNavigate();
  const { token } = useParams();
  const { setMessage } = useMessage();
  const { t } = useTranslation();

  useEffect(() => {
    const totalImages = 7;
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const imagePath = `${process.env.PUBLIC_URL}/images/backgrounds/nextrust${randomIndex}.jpg`;
    setBackgroundImage(imagePath);

    const handleScroll = () => setOffsetY(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const validatePassword = (newPassword, newConfirmPassword) => {
    const requirements = {
      minLength: newPassword.length >= 8,
      upperCase: /[A-Z]/.test(newPassword),
      lowerCase: /[a-z]/.test(newPassword),
      number: /[0-9]/.test(newPassword),
      symbol: /[^A-Za-z0-9]/.test(newPassword),
      passwordsMatch: newPassword === newConfirmPassword
    };
    setPasswordRequirements(requirements);
    return Object.values(requirements).every(Boolean);
  };

  const handlePasswordChange = e => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword, confirmPassword);
  };

  const handleConfirmPasswordChange = e => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    validatePassword(password, newConfirmPassword);
  };

const handleSubmit = async e => {
  e.preventDefault();
  if (!validatePassword(password, confirmPassword)) {
    setMessage(t('Passwords do not meet requirements or do not match.'));
    return;
  }
  try {
    const response = await axios.post('/users/reset-password', { token, password });
    setMessage(t('Your password has been reset successfully.'));
    navigate('/login');
  } catch (error) {
    setMessage(t('Failed to reset password. Please try again later.'));
  }
};


  const formatRequirement = (flag, text) => <span style={{ color: flag ? '#081a44' : 'red' }}>{text}</span>;

  return (
    <div className="nextrust-starting-page" style={{ backgroundImage: `url(${backgroundImage})`, backgroundPositionY: offsetY * 0.3 }}>
      <div className="overlay-content">
        <form onSubmit={handleSubmit}>
          <h2>{t('reset_password_button')}</h2>
          <div className="form-group">
            <label>{t('password')}</label>
            <input
              type="password"
              placeholder="New Password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
          </div>
          <div className="form-group">
            <label>{t('confirm_password')}</label>
            <input
              type="password"
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              required
            />
          </div>
          <div className="form-group">
                 <p>{formatRequirement(passwordRequirements.minLength, t('At least 8 characters'))}</p>
<p>{formatRequirement(passwordRequirements.upperCase, t('An uppercase letter'))}</p>
<p>{formatRequirement(passwordRequirements.lowerCase, t('A lowercase letter'))}</p>
<p>{formatRequirement(passwordRequirements.number, t('A number'))}</p>
<p>{formatRequirement(passwordRequirements.symbol, t('A special character'))}</p>
<p>{formatRequirement(passwordRequirements.passwordsMatch, t('Passwords match'))}</p>
          </div>
          <button type="submit">{t('reset_password_button')}</button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
