import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../axios';
import Web3 from 'web3';
import Presale from '../adminfiles/Presale.json';
import NexTrustTokenContract from '../adminfiles/NexTrustToken.json';
import { useMessage } from '../MessageContext';
import UpdateWallet from './UpdateWallet'; 
const web3 = new Web3(window.ethereum);
const presaleContractAddress = process.env.REACT_APP_PRESALE_ADDRESS;
const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;
const mainWalletAddress = process.env.REACT_APP_TOKEN_NEXTRUST_MAIN_WALLET;
const presaleContract = new web3.eth.Contract(Presale.abi, presaleContractAddress);
const tokenContract = new web3.eth.Contract(NexTrustTokenContract.abi, tokenAddress);
const TokenPurchase = () => {
    const [usdAmount, setUsdAmount] = useState('');
    const [validationMessage, setValidationMessage] = useState('');
	const [ethPrice, setEthPrice] = useState('');
    const [tokens, setTokens] = useState('');
    const [ethRequired, setEthRequired] = useState('');
	const [showPopup, setShowPopup] = useState(false);
   	const [checkedDisclaimer, setCheckedDisclaimer] = useState(false);
    const [checkedPrivacy, setCheckedPrivacy] = useState(false);
    const [checkedTerms, setCheckedTerms] = useState(false);
    const [showWalletUpdate, setShowWalletUpdate] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
    const [stageDetails, setStageDetails] = useState({});
    const [usdPerToken, setUsdPerToken] = useState('');
	const navigate = useNavigate();
	const { setMessage } = useMessage();
	const { t } = useTranslation();
    const [showCloseButton, setShowCloseButton] = useState(false);
	useEffect(() => {
    const fetchETHPrice = async () => {
        try {
            const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd');
            const price = response.data.ethereum.usd; 
            setEthPrice(price);
            localStorage.setItem('ethPrice', price.toString()); 
        } catch (error) {
setMessage(t('Failed to fetch ETH price. Please try again.'));
        }
    };
    fetchETHPrice();
}, []);
	
	
	  useEffect(() => {
        axiosInstance.get('/token/presaleDetails')
            .then(response => {
                const { usdPerToken } = response.data;
                const formattedUsdPerToken = (usdPerToken / 1000000).toFixed(6);
                setUsdPerToken(formattedUsdPerToken);
            })
            .catch(error => {
            });
    }, []);
	
	
useEffect(() => {
    if (usdAmount && ethPrice && usdPerToken) {
        const ethToPay = parseFloat(usdAmount) / parseFloat(ethPrice);
        setEthRequired(ethToPay.toFixed(6));
        const tokensToGet = parseFloat(usdAmount) / parseFloat(usdPerToken);
        setTokens(new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(tokensToGet));
    }
}, [usdAmount, ethPrice, usdPerToken]);
	
	
	
const calculateAndShowPopup = async () => {
    try {
        const response = await axiosInstance.post('/token/calculateTokensAndETH', { usdAmount });
        const { tokens, ethRequired } = response.data;
        setTokens(tokens);
        const scaledEthRequired = parseFloat(ethRequired) / 1e6;
        setEthRequired(scaledEthRequired.toFixed(6));
        setShowPopup(true);
    } catch (error) {
        let errorMessage = "Failed to calculate. Please try again later.";
        if (error.response && error.response.data && error.response.data.error) {
            errorMessage = error.response.data.error; 
        }
        setMessage(t(errorMessage));
    }
};
const handleConfirmationAttempt = (actionFunction) => {
    if (!checkedDisclaimer) {
        setMessage(t("You must agree to the Purchase Agreement to proceed."));
    } else {
        actionFunction();
    }
};
const reserveTokens = async () => {
    const userId = localStorage.getItem('userId');
    try {
        const response = await axiosInstance.post('/token/reserveTokens', {
            userId, tokens, ethRequired, usdAmount
        });
        const { transactionId } = response.data; 
        setMessage(t("Reservation successful! Please follow the instructions to complete the ETH transfer."));
        setTimeout(() => {
            navigate(`/nextrust/transactions/${transactionId}`); 
        }, 2000);
    } catch (error) {
        if (error.response && error.response.data) {
            setMessage(t('Error: {{error}}', { error: error.response.data }));
        } else {
            setMessage(t("An unexpected error occurred."));
        }
    }
};
const confirmPurchase = async () => {
    const userId = localStorage.getItem('userId');
    const transactionId = `${Date.now()}`;
    setIsLoading(true);  
    try {
        if (window.ethereum) {
            await window.ethereum.enable();
            const accounts = await web3.eth.getAccounts();
            if (accounts.length === 0) {
                setMessage(t('Please log in to MetaMask to continue.'));
                setIsLoading(false); 
                return;
            }
            const userAccount = accounts[0];
            const ethAmountInWei = web3.utils.toWei(ethRequired, 'ether');
            await presaleContract.methods.buyTokens(tokens, transactionId, userAccount, ethAmountInWei).send({
                from: userAccount,
                value: ethAmountInWei
            })
            .on('transactionHash', (hash) => {
                setMessage(t('Transaction in progress. Hash: {{hash}}', { hash }));
            })
            .on('receipt', async (receipt) => {
                setMessage(t('Purchase successful!'));
                try {
                    await axiosInstance.post('/token/buyTokens', {
                        userId, transactionId, usdAmount, tokensToReceive: tokens, ethRequired, txHash: receipt.transactionHash
                    });
                } catch (error) {
                    setMessage(t('Failed to save transaction.'));
                } finally {
                    setIsLoading(false);
                }
            })
            .on('error', (error) => {
                setMessage(t('Transaction failed. Please try again.'));
                setIsLoading(false);
            });
        } else {
            setMessage(t('Please install MetaMask to continue.'));
            setIsLoading(false);
        }
    } catch (error) {
        setMessage(t('Failed to complete the purchase. Please try again.'));
        setIsLoading(false);
    }
};
    const closePopup = () => {
        setShowWalletUpdate(false);
        setShowCloseButton(false);
    };
	
	
 useEffect(() => {
        checkWalletAddress();
    }, []);
    const checkWalletAddress = async () => {
        const userId = localStorage.getItem('userId');
        try {
            const response = await axiosInstance.get(`/token/getWallet?userId=${userId}`);
            if (!response.data.walletAddress) {
                setShowWalletUpdate(true); 
            }
        } catch (error) {
        }
    };
const onWalletUpdate = () => {
    setShowWalletUpdate(false);
	setShowCloseButton(true);
    setMessage(t("Wallet address updated successfully. Please use the same wallet for transactions to avoid loss of tokens."));
};
	
	    const handleUsdAmountChange = (e) => {
        const amount = e.target.value;
        setUsdAmount(amount);
        if (parseFloat(amount) < 5) {
            setValidationMessage(t('The minimum amount is $250.'));
        } else {
            setValidationMessage(''); // Clear message if the amount is valid
        }
    };
  return (
    <div className="user-update-container">
        {isLoading && (
            <div className="loading-overlay">
                <p>{t('Loading... this might take some time.')}</p>
            </div>
        )}
        <h1>{t('headline_buy_nxt')}</h1>
        <h2>{t('text_buy_nxt')}</h2>
        <p>{t('option_one')}</p>
        <p>{t('option_two')}</p>
        <p>{t('You will pay')} ${usdPerToken} {t('for each token.')}</p>
        <p><br></br></p>

        <div className="form-field">
                <input
                    type="number"
                    placeholder={t('USD you want to exchange into NXT')}
                    value={usdAmount}
                    onChange={handleUsdAmountChange}
                />
                {validationMessage && <p className="validation-message">{validationMessage}</p>}
                <button onClick={calculateAndShowPopup} className="update-button" disabled={!!validationMessage || !usdAmount}>{t('Purchase')}</button>
            </div>
        <p><br></br></p>
        {tokens && ethRequired && (
            <div>
                <p>{t('NXT Tokens:')} {tokens}</p>
                <p>{t('ETH:')} {ethRequired}</p>
            </div>
        )}
        {showPopup && (
            <div className="popup-overlay">
                <div className="popup">
                    <button onClick={closePopup} className="close-button">X</button>
                    <h2>{t('USD Amount:')} {usdAmount}</h2>
                    <h2>{t('Token Amount:')} {tokens}</h2>
                    <h2>{t('ETH Required:')} {ethRequired}</h2>
                    <p><br></br></p>
                    <p>
                        <label>
                            <input type="checkbox" checked={checkedDisclaimer} onChange={() => setCheckedDisclaimer(!checkedDisclaimer)}/>
                            {t('I have read and agree to the')} <a href="/disclaimer" target="_blank" rel="noopener noreferrer">{t('purchase_agreement')}</a>.
                        </label>
                    </p>
                    <button onClick={() => handleConfirmationAttempt(reserveTokens)}>{t('Reserve and Pay Later')}</button> 
                </div>
            </div>
        )}
        <p><br></br></p>
        <p><br></br></p>
        <p className="disclaimer-text"><strong>{t('disclaimer')}</strong> {t('All transactions are irreversible. Verify the ETH amount required and ensure accuracy before transferring funds. We cannot be held responsible for any loss due to incorrect transaction amounts or failure to follow instructions. By initiating a transaction, you acknowledge and accept the potential risks, including total loss of funds.')}</p>
        {showWalletUpdate && (
            <div className="overlay-walletcheck">
                <div className="overlay-walletcheck-content">
                    <UpdateWallet onWalletUpdate={onWalletUpdate} />
			 {showCloseButton && (
         					<button onClick={closePopup} className="close-button-wallet">Close</button>
                        )}
                </div>
            </div>
        )}
    </div>
);
};
export default TokenPurchase;