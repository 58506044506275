import React, { useState } from 'react';
import axiosInstance from '../axios';
import { useTranslation } from 'react-i18next';
import { useMessage } from '../MessageContext';

const TokenReservation = () => {
  const [usdAmount, setUsdAmount] = useState('');
  const [tokens, setTokens] = useState('');
  const [ethRequired, setEthRequired] = useState('');
  const { setMessage } = useMessage();
  const [showPopup, setShowPopup] = useState(false);
  const { t } = useTranslation();

  const calculateAndShowPopup = async () => {
    try {
      const response = await axiosInstance.post('/token/calculateTokensAndETH', { usdAmount });
      const { tokens, ethRequired } = response.data;
      setTokens(tokens);
      setEthRequired(ethRequired);
      setShowPopup(true);
    } catch (error) {
      setMessage(t('Failed to calculate. Please try again later.'));
    }
  };

  const reserveTokens = async () => {
    const userId = localStorage.getItem('userId');
    try {
        await axiosInstance.post('/token/reserveTokens', {
            userId, tokens, ethRequired, usdAmount
        });
        setMessage(t("Reservation successful! Please follow the instructions to complete the ETH transfer."));
    } catch (error) {
        setMessage(t('Error reserving tokens. Please try again.'));
    }
  };

  return (
    <div>
      <h2>{t('Reserve Tokens')}</h2>
      <input
        type="number"
        placeholder={t('Amount in USD')}
        value={usdAmount}
        onChange={(e) => setUsdAmount(e.target.value)}
      />
      <button onClick={calculateAndShowPopup}>{t('Calculate')}</button>
      {showPopup && (
        <div>
          <p>{t('Token Amount: {tokens}', { tokens })}</p>
          <p>{t('ETH Required: {ethRequired}', { ethRequired })}</p>
          <button onClick={reserveTokens}>{t('Reserve')}</button>
        </div>
      )}
    </div>
  );
};

export default TokenReservation;
