import React from 'react';
import { Navigate } from 'react-router-dom';

const AuthPages = ({ children }) => {
  const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      // No need to clear anything if the token isn't there
      return false;
    }
    try {
      const { exp } = JSON.parse(atob(token.split('.')[1]));
      if (exp * 1000 > new Date().getTime()) {
        return true;
      } else {
        // Token has expired, remove it without clearing everything
        localStorage.removeItem('token'); // Remove just the token
        // Optionally, remove any other session-specific keys here
        // localStorage.removeItem('anotherSessionKey');
        return false;
      }
    } catch (error) {
      // Error in parsing the token, remove it
      localStorage.removeItem('token'); // Remove just the token
      return false;
    }
  };

  return isAuthenticated() ? children : <Navigate to="/" />;
};

export default AuthPages;
