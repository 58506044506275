import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MessageProvider, useMessage } from './MessageContext';

import TokenDistribution from './admin/tokenDistribution';
import ShowTokenBalance from './token/showTokenBalance';
import TokenPurchase from './token/TokenPurchase';
import UpdateWallet from './token/UpdateWallet';
import AppHeaderBar from './AppHeaderBar.js';
import PreSaleStart from './admin/PreSaleStart.js';
import PresaleDetails from './token/PresaleDetails';
import TotalTokenSupply from './token/totalTokenSupply';
import MainWalletBalance from './token/mainWalletBalance';
import TokenReservation from './token/TokenReservation';
import ReservationsList from './admin/ReservationsList';
import AppMainNavBar from './AppMainNavBar.js';
import PresaleTokensRemaining from './token/presaleTokensRemaining.js';
import PresaleTokensSold from './token/presaleTokensSold.js';
import ClaimTokens from './token/claimTokens.js';
import UpdateUser from './user/UpdateUser.js';
import VerifyEmailUpdate from './user/VerifyEmailUpdate.js';
import MessagePopup from './MessagePopup';
import UserTransactionsList from './token/UserTransactionsList';
import NexTrustBackground from './NexTrustBackground';
import UserWallet from './token/UserWallet.js';


import './App.css';

function App() {

  return (
    <div>
<MessageProvider>
      
	  <AppHeaderBar />
	  
	  <NexTrustBackground />

	  <div className="content-container">
        <div className="main-content">
          <Routes>
            <Route path="/" element={<TokenPurchase />} />
            <Route path="/token-distribution" element={<TokenDistribution />} />
            <Route path="/show-token-balance" element={<ShowTokenBalance />} />
            <Route path="/token-purchase" element={<TokenPurchase />} />
	        <Route path="/token-reservation" element={<TokenReservation />} />
            <Route path="/update-wallet" element={<UpdateWallet />} />
            <Route path="/presale-start" element={<PreSaleStart />} />
            <Route path="/main-wallet-balance" element={<MainWalletBalance />} />
            <Route path="/reservations-list" element={<ReservationsList />} />
            <Route path="/ClaimTokens" element={<ClaimTokens />} />
            <Route path="/UpdateUser" element={<UpdateUser />} />
            <Route path="/verify/:token" element={<VerifyEmailUpdate />} />
	  		<Route path="/transactions" element={<UserTransactionsList />} />
	  		<Route path="/transactions/:transactionId" element={<UserTransactionsList />} />

          </Routes>
        </div>
        
	  <div className="sidebar">
          <div className="section news">
            <TotalTokenSupply />
          </div>
          <div className="section tips">
            <PresaleDetails />
          </div>
	  	  <div className="section tips">
            <UserWallet />
          </div>
      </div>
	            <MessagePopup />

      </div>
</MessageProvider>

    </div>
  );
}

export default App;
