import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './fonts.css';

import NexTrustApp from './App.js';
import NexTrustHomePage from './HomePage.js';

import CookieConsentManager from './cookies/CookieConsentManager.js';
import initializeTagManager from './cookies/googleTagManager.js';
import { initializeGoogleAnalytics } from './cookies/initializeGoogleAnalytics.js';
import axios from './axios.js'; 
import AuthPages from './components/security/authPages.js';

const GF_CONSENT_KEY = 'user_gf_consent';

function NexTrust() {
  const [showBanner, setShowBanner] = useState(false);
  const [isGATrackingAllowed, setIsGATrackingAllowed] = useState(() => JSON.parse(localStorage.getItem('user_ga_consent')) ?? null);
  const [isGTMTrackingAllowed, setIsGTMTrackingAllowed] = useState(() => JSON.parse(localStorage.getItem('user_gtm_consent')) ?? null);
  const [isGFAllowed, setIsGFAllowed] = useState(() => JSON.parse(localStorage.getItem(GF_CONSENT_KEY)) ?? null);

  const checkTokenValidity = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    try {
      const { exp } = JSON.parse(atob(token.split('.')[1]));
      return exp * 1000 > new Date().getTime();
    } catch (error) {
      return false;
    }
  };

  const className = isGFAllowed ? 'poppins' : 'alternate';


  useEffect(() => {
  }, [className]);

  useEffect(() => {

    const handleStorageChange = () => {
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  useEffect(() => {
    if (isGATrackingAllowed !== null && isGTMTrackingAllowed !== null) {
      initializeTagManager(isGTMTrackingAllowed);
      initializeGoogleAnalytics(isGATrackingAllowed);
    }
  }, [isGATrackingAllowed, isGTMTrackingAllowed]);

  const handleGAConsentChange = (consent) => {
    setIsGATrackingAllowed(consent);
  };

  const handleGTMConsentChange = (consent) => {
    setIsGTMTrackingAllowed(consent);
  };

  const openCookieConsentManager = useCallback(() => {
    setShowBanner(true);
  }, []);

  return (
    <Router>
        <CookieConsentManager
          showBanner={showBanner}
          setShowBanner={setShowBanner}
          onGAConsentChange={handleGAConsentChange}
          onGTMConsentChange={handleGTMConsentChange}
        />
    <div className={className}>
          <Routes>
          <Route path="/*" element={<NexTrustHomePage openCookieConsentManager={openCookieConsentManager} />} />
 			<Route path="/nextrust/*" element={
	  			<AuthPages>
              <NexTrustApp openCookieConsentManager={openCookieConsentManager} />
              	</AuthPages>
            									} />          
	  		</Routes>
        </div>
    </Router>
  );
}


export default NexTrust;
