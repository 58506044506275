import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios';
import Web3 from 'web3';
import { useTranslation } from 'react-i18next';

function MainWalletBalance() {
   const [walletBalance, setWalletBalance] = useState('');
  const { t } = useTranslation();

	  useEffect(() => {
      axiosInstance.get('/token/walletBalance')
  .then(response => {
    const balance = response.data.walletBalance;
    const formattedBalance = new Intl.NumberFormat().format(balance);
    setWalletBalance(formattedBalance);
  })
  .catch(error => {
     if (error.response) {
         } else if (error.request) {
       } else {
       }

    if (error.config) {
    }
  });

  }, []);

  return (
	          <p>{t('Wallet Balance')} {walletBalance}</p>
	     );
}

export default MainWalletBalance;
