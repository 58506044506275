import { useNavigate, Routes, Route, Link } from 'react-router-dom';
import { MessageProvider, useMessage } from './MessageContext';

import './homepage.css';

import HomePageHeaderBar from './public/HomePageHeaderBar.js';
import NexTrustStartingPage from './public/NexTrustStartingPage.js';
import Register from './user/registerPage.js';
import Login from './user/loginPage.js';
import ConfirmEmailPage from './user/confirmEmailPage.js';
import ResetPassword from './user/ResetPasswordPage.js';

import NexTrustProductPage from './public/NexTrustProductPage.js'
import NexTrustRoadmapPage from './public/NexTrustRoadmapPage.js'
import NexTrustBusinessModelPage from './public/NexTrustBusinessModelPage.js'
import NexTrustFooterPage from './public/NexTrustFooterPage.js'
import NexTrustTokenEconomyPage from './public/NexTrustTokenEconomyPage.js'
import NexTrustContactPage from './public/NexTrustContactPage.js'

import NexTrustImprint from './public/NexTrustImprint.js'
import NexTrustPrivacy from './public/NexTrustPrivacy.js'
import NexTrustTermsConditions from './public/NexTrustTermsConditions.js'
import NexTrustDisclaimer from './public/NexTrustDisclaimer.js'
import NexTrustJobs from './public/NexTrustJobs.js'

import useScrollToTop from './useScrollToTop';


import MessagePopup from './MessagePopup';


function NexTrustHomePage({ openCookieConsentManager }) {
	useScrollToTop();
  return (
   <>
   	<MessageProvider>

	  <HomePageHeaderBar />
	  
	  <Routes>
            <Route path="/" element={<NexTrustStartingPage />} />
	  		<Route path="/register" element={<Register />} />
	  	  	<Route path="/login" element={<Login />} />
	  	  	<Route path="/verify-email" element={<ConfirmEmailPage />} />
	  		<Route path="/reset-password/:token" element={<ResetPassword />} />
	  	  	<Route path="/product" element={<NexTrustProductPage />} />
	  	  	<Route path="/roadmap" element={<NexTrustRoadmapPage />} />
	  	  	<Route path="/business-model" element={<NexTrustBusinessModelPage />} />
	  	  	<Route path="/token-economy" element={<NexTrustTokenEconomyPage />} />
	  	  	<Route path="/contact" element={<NexTrustContactPage />} />
	  	  	<Route path="/imprint" element={<NexTrustImprint />} />
	  	  	<Route path="/privacy" element={<NexTrustPrivacy />} />
	  	  	<Route path="/termsconditions" element={<NexTrustTermsConditions />} />
	  	  	<Route path="/disclaimer" element={<NexTrustDisclaimer />} />
	  	  	<Route path="/jobs" element={<NexTrustJobs />} />
      </Routes>

            <NexTrustFooterPage openCookieConsentManager={openCookieConsentManager} />
	  <MessagePopup />
  	</MessageProvider>
  </>
  );
}

export default NexTrustHomePage;
