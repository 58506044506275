import { NavLink, useLocation } from 'react-router-dom';
import './App.css';

function AppMainNavBar() {
  const location = useLocation();

  const getNavLinkClass = (path) => {
    return location.pathname === path ? "nav-link settings-button active-link" : "nav-link settings-button";
  };

  return (
    <div className="app-main-nav">
      <div className="main-nav-section">
        <NavLink to="/nextrust/token-purchase" className={getNavLinkClass("/nextrust/token-purchase")}>Get NXT Tokens</NavLink>
        <NavLink to="/nextrust/claimTokens" className={getNavLinkClass("/nextrust/claimTokens")}>Claim Tokens</NavLink>
		<NavLink to="/nextrust/transactions" className={getNavLinkClass("/nextrust/transactions")}>Transactions</NavLink>


      </div>
      <div className="settings-section">
		<NavLink to="/nextrust/token-distribution" className={getNavLinkClass("/nextrust/token-distribution")}>Token Distribution</NavLink>
        <NavLink to="/nextrust/reservations-list" className={getNavLinkClass("/nextrust/reservations-list")}>Reservations List</NavLink>
        <NavLink to="/nextrust/show-token-balance" className={getNavLinkClass("/nextrust/show-token-balance")}>Show Token Balance</NavLink>
        <NavLink to="/nextrust/presale-start" className={getNavLinkClass("/nextrust/presale-start")}>PreSale Start</NavLink>
        <NavLink to="/nextrust/main-wallet-balance" className={getNavLinkClass("/nextrust/main-wallet-balance")}>Main Wallet Balance</NavLink>

      </div>
    </div>
  );
}

export default AppMainNavBar;
