import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'; 
import axios from '../axios.js';
import { useNavigate, NavLink } from 'react-router-dom';
import { useMessage } from '../MessageContext';

function Register() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [securityKey, setSecurityKey] = useState(''); 
  const [backgroundImage, setBackgroundImage] = useState('');
  const [offsetY, setOffsetY] = useState(0);
  const navigate = useNavigate();
  const { setMessage } = useMessage();
  const { t } = useTranslation();  
	const [checkedDisclaimer, setCheckedDisclaimer] = useState(false);
    const [checkedPrivacy, setCheckedPrivacy] = useState(false);
    const [checkedTerms, setCheckedTerms] = useState(false);
	
  const handleSubmit = async e => {
    e.preventDefault();

    if (!checkedPrivacy) {
      setMessage(t("You must agree to the Data Privacy Policy to proceed."));
      return;
    } else if (!checkedTerms) {
      setMessage(t("You must agree to the Terms and Conditions to proceed."));
      return;
    }

    setMessage(t('Processing your registration...'));
    try {
      const body = JSON.stringify({ name, email });
      const config = { headers: { 'Content-Type': 'application/json' } };
      const res = await axios.post('/users/register', body, config);
      setMessage(t('Registration successful! Welcome to NexTrust! Please check your email to confirm your account.'));
      navigate('/login');
    } catch (err) {
      if (err.response) {
        setMessage(t('Registration failed. Please try again.', {errorMessage: err.response.data.message}));
      } else if (err.request) {
        setMessage(t('Registration failed: No response from the server. Please check your network connection.'));
      } else {
        setMessage(t('Registration failed: An unexpected error occurred.', {error: err.message}));
      }
    }
  };

	
const handleConfirmationAttempt = (actionFunction) => {
  if (!checkedPrivacy) {
    setMessage(t("You must agree to the Data Privacy Policy to proceed."));
  } else if (!checkedTerms) {
    setMessage(t("You must agree to the Terms and Conditions to proceed."));
  } else {
    actionFunction();
  }
};


  useEffect(() => {
    const totalImages = 7;
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const imagePath = `${process.env.PUBLIC_URL}/images/backgrounds/nextrust${randomIndex}.jpg`;
    setBackgroundImage(imagePath);

    const handleScroll = () => setOffsetY(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

 return (
    <div className="nextrust-starting-page" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="overlay-content" style={{ top: `${offsetY * 0.3}px` }}>
        <form onSubmit={handleSubmit}>
          <h2>{t('Register to Your NexTrust Account')}</h2>
          <p><br></br></p>
          <div className="form-group">
            <label>{t('name')}</label>
            <input type="text" value={name} onChange={e => setName(e.target.value)} required />
          </div>
          <div className="form-group">
            <label>{t('email')}</label>
            <input type="email" value={email} onChange={e => setEmail(e.target.value)} required />
          </div>
          <div className="form-group">
            <p><br></br></p><p><br></br></p>
          </div>
         
          <div className="form-group">
            <p>
              <label>
                <input type="checkbox" checked={checkedPrivacy} onChange={() => setCheckedPrivacy(!checkedPrivacy)}/>
                {t('I have read and agree to the')} <a href="/privacy" target="_blank" rel="noopener noreferrer">{t('data_privacy')}</a>.
              </label>
            </p>
          </div>
          <div className="form-group">
            <p>
              <label>
                <input type="checkbox" checked={checkedTerms} onChange={() => setCheckedTerms(!checkedTerms)}/>
                {t('I have read and agree to the')} <a href="/termsconditions" target="_blank" rel="noopener noreferrer">{t('terms_conditions')}</a>.
              </label>
            </p>
          </div>
          <button type="submit">{t('register_button')}</button>
          <div className="nav-links-container">
            <NavLink to="/login">{t('login')}</NavLink>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
