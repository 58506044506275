 import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'; 

function NexTrustPrivacy() {
  const [backgroundImage, setBackgroundImage] = useState('');
  const [offsetY, setOffsetY] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const totalImages = 7;
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const imagePath = `${process.env.PUBLIC_URL}/images/backgrounds/nextrust${randomIndex}.jpg`;
    setBackgroundImage(imagePath);

    const handleScroll = () => setOffsetY(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);




  return (
    <>
 <div className="nextrust-other-page" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="overlay-content" style={{ top: `${offsetY * 0.3}px` }}>
          <h1>{t('we_take_serious')}</h1>
         
         </div>
      </div>

<div className="content-section-homepage">

<div className="grid-content-item col-six">
<h2>{t('privacy1')}</h2>
<p><br></br>
{t('privacy2')}
<br></br>
<br></br>
</p>

<h2>{t('privacy3')}</h2>
<p>
{t('responsible')}:<br></br>
Appadise UG<br></br>
Sierichstr. 168<br></br>
22299 {t('hamburg')}<br></br>
{t('germany')}<br></br>
<br></br>
{t('email')}: jenny@nextrust.io<br></br>
www.nextrust.io<br></br>
</p>
</div>
</div>

<div className="content-section-homepage">
<div className="grid-content-item col-six">
<p>{t('privacy4')}<br></br><br></br></p>


<h2>{t('privacy5')}</h2>
<p>{t('privacy6')}<br></br>
<br></br>
{t('privacy7')}<br></br>
{t('privacy8')}<br></br>
{t('privacy9')}<br></br>
{t('privacy10')}<br></br>
{t('privacy11')}<br></br>
{t('privacy12')}<br></br>
<br></br>
</p>
<h2>{t('privacy13')}</h2>
<p>
{t('privacy14')}<br></br>
{t('privacy15')}<br></br>
{t('privacy16')}<br></br>
{t('privacy17')}<br></br>
<br></br>
{t('privacy18')}
<br></br><br></br>
{t('privacy19')}<br></br><br></br></p>
<h2>{t('privacy20')}</h2>
<p>{t('privacy21')}
<br></br><br></br>
{t('privacy22')}
<br></br><br></br>
{t('privacy22')}
<br></br><br></br>
{t('privacy23')}
<br></br><br></br>
{t('privacy24')} {t('privacy25')}
<br></br><br></br>
{t('privacy26')}<br></br><br></br></p>
<h2>{t('privacy27')}</h2>
<p>{t('privacy28')}<br></br>
<br></br>
{t('privacy29')}<br></br>
<br></br>{t('privacy30')} <br></br><br></br> {t('privacy31')}<br></br><br></br></p>
<h2>{t('privacy32')}</h2>
<p>{t('privacy33')}<br></br>
<br></br>
{t('privacy34')}<br></br>
{t('privacy35')}<br></br>
{t('privacy36')}<br></br>
{t('privacy37')}<br></br>
{t('privacy38')}<br></br>
{t('privacy39')}<br></br>
<br></br>
{t('privacy40')}<br></br>
<br></br>
{t('privacy41')}<br></br>
{t('privacy42')}<br></br>
{t('privacy43')}<br></br>
{t('privacy44')}<br></br>
<br></br>
{t('privacy45')}<br></br>
<br></br>
{t('privacy46')}<br></br>
<br></br>
{t('privacy47')}<br></br><br></br></p>
<h2>{t('privacy48')}</h2>
<p>{t('privacy49')}<br></br>
<br></br>
{t('privacy50')}<br></br>
{t('privacy51')}<br></br>
{t('privacy52')} {t('privacy53')}<br></br>
{t('privacy54')}<br></br>
<br></br>
{t('privacy55')}<br></br>
<br></br>
{t('privacy56')}<br></br>
<br></br></p>
<h2>{t('privacy57')}</h2>
<p>
{t('privacy58')}
<br></br><br></br>
{t('privacy59')}
<br></br><br></br>
{t('privacy60')}
<br></br><br></br>
{t('privacy61')}
<br></br><br></br>
{t('privacy62')}
<br></br><br></br>
{t('privacy63')}<br></br><br></br></p>
<h2>{t('privacy64')}</h2>
<p>{t('privacy65')}<br></br>
<br></br>
{t('privacy66')}<br></br>
<br></br></p>
<h2>{t('privacy67')}</h2>
<p>{t('privacy68')}<br></br>
<br></br>
{t('privacy69')}<br></br>
{t('privacy70')}<br></br>
{t('privacy71')}<br></br>
{t('privacy72')}<br></br>
{t('privacy73')}<br></br>
{t('privacy74')}<br></br>
<br></br>
{t('privacy75')}
<br></br><br></br>
{t('privacy76')}<br></br><br></br>

{t('privacy77')}<br></br><br></br>

{t('privacy78')}<br></br>
<br></br>
{t('privacy79')}
<br></br><br></br></p>

<h2>{t('privacy80')}</h2>
<p>{t('privacy81')}<br></br>
<br></br></p>
<h2>{t('privacy82')}</h2>
<p>{t('privacy83')}<br></br>

{t('privacy84')}
<br></br><br></br>
{t('privacy85')}
<br></br><br></br>
{t('privacy86')}
<br></br><br></br>
{t('privacy87')}
<br></br><br></br>
{t('privacy88')}
<br></br><br></br>
{t('privacy89')}
<br></br><br></br>
{t('privacy90')}
<br></br><br></br></p>
<h2>{t('privacy91')}</h2>
<p>{t('privacy92')}
<br></br><br></br>
{t('privacy93')}<br></br><br></br></p>
<h2>{t('privacy94')}</h2>
<p>{t('privacy95')}
<br></br><br></br>
{t('privacy96')}
<br></br><br></br>
<h2>{t('privacy97')}</h2>
<p>{t('privacy98')}</p>
<br></br></p>


<h2>{t('privacy99')}</h2>
<p>{t('privacy100')}<br></br><br></br></p>

<h1>{t('privacy101')}</h1>
<p>{t('privacy102')}<br></br><br></br></p>
<h2>{t('privacy103')}</h2>
<p>{t('privacy104')}


<br></br><br></br></p>
<h2>{t('privacy105')}</h2>
<p>
{t('privacy106')}<br></br><br></br>
</p>

<h2>{t('privacy107')}</h2>

<p>{t('privacy108')}
<br></br><br></br>
{t('privacy109')}
<br></br><br></br>
{t('privacy110')}


<br></br><br></br></p>

<h2>{t('privacy111')}</h2>
<p>{t('privacy112')}<br></br><br></br></p>

<h2>{t('privacy113')}</h2>

<p>{t('privacy114')}
<br></br><br></br>
{t('privacy115')}<br></br><br></br>
{t('privacy116')}
<br></br><br></br></p>
<h2>{t('privacy117')}</h2>

<p>{t('privacy118')}
<br></br><br></br>
{t('privacy119')}<br></br><br></br>
{t('privacy120')}<br></br><br></br></p>


<h2>{t('privacy121')}</h2>
<p>
{t('privacy122')}
<br></br><br></br>
{t('privacy123')}<br></br><br></br></p>
<h2>{t('privacy124')}</h2>
<p>
{t('privacy125')}<br></br><br></br>

{t('privacy126')}
<br></br><br></br></p>


<h2>{t('privacy127')}</h2>
<p>
{t('privacy128')}<br></br><br></br></p>

<h2>{t('privacy129')}</h2>
<p>
{t('privacy130')}
<br></br><br></br>
{t('privacy131')}<br></br><br></br>

{t('privacy132')}
<br></br><br></br></p>
<h2>{t('privacy133')}</h2>
<p>
{t('privacy134')}
<br></br><br></br></p>
<h2>{t('privacy135')}</h2>
<p>
{t('privacy136')}<br></br><br></br></p>

<h2>{t('privacy137')}</h2>
<p>
{t('privacy138')}
<br></br><br></br></p>
<h2>{t('privacy139')}</h2>

<p>{t('privacy140')}<br></br>
<br></br>
{t('privacy141')}<br></br><br></br></p>

<h2>{t('privacy142')}</h2>
<p>
{t('privacy143')}
<br></br><br></br>
{t('privacy144')}
<br></br><br></br>
{t('privacy145')}<br></br><br></br></p>
<h2>{t('privacy146')}</h2>
<p>
{t('privacy147')}
<br></br><br></br>
{t('privacy148')}<br></br><br></br>
{t('privacy149')}<br></br><br></br></p>
<h2>{t('privacy150')}</h2>
<p>
{t('privacy151')}
<br></br><br></br>
{t('privacy152')}<br></br><br></br></p>
<h2>{t('privacy153')}</h2>
<p>
{t('privacy154')}<br></br><br></br>
{t('privacy155')}<br></br><br></br>
{t('privacy156')}
<br></br><br></br>
{t('privacy157')}
<br></br><br></br></p>
<h2>{t('privacy158')}</h2>
<p>
{t('privacy159')}
<br></br><br></br>
{t('privacy160')}
<br></br><br></br>
{t('privacy161')}
<br></br><br></br></p>
<h2>{t('privacy162')}</h2>
<p>
{t('privacy163')}<br></br><br></br>
{t('privacy164')}<br></br><br></br></p>


<h2>{t('privacy165')}</h2>
<p>
{t('privacy166')}<br></br><br></br>
{t('privacy167')}<br></br><br></br>
{t('privacy168')}<br></br><br></br>
{t('privacy169')}<br></br><br></br>
{t('privacy170')}<br></br><br></br>
{t('privacy171')}<br></br><br></br></p>
<h2>{t('privacy172')}</h2>
<p>
{t('privacy173')}
<br></br><br></br></p>
</div>

      </div>

</>
);
}

export default NexTrustPrivacy;



