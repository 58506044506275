import React, { useState } from 'react';
import axios from '../axios.js';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useMessage } from '../MessageContext';

const ResendConfirmation = () => {
    const [email, setEmail] = useState('');
  const { setMessage } = useMessage();
    const { t } = useTranslation();

const handleResend = async (e) => {
    e.preventDefault();
    const currentLanguage = i18n.language;

    try {
        const data = {
            email: email,
            language: currentLanguage,
        };

        const response = await axios.post('/users/resend-confirmation', data);
        setMessage(response.data.msg);
    } catch (error) {
        setMessage(error.response?.data?.msg || "An error occurred.");
    }
};

    return (
        <div>
            <h1>{t('resend_confirmation_email')}</h1><br></br>
            <form onSubmit={handleResend}>
                <input
                    type="email"
                    placeholder={t('your_email')}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <button className="save">{t('resend_confirmation_email_button')}</button>
            </form>
        </div>
    );
};

export default ResendConfirmation;
