import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios';
import { useTranslation } from 'react-i18next';

const PresaleTokensSold = () => {
  const [totalTokensSoldAndConfirmed, setTotalTokensSoldAndConfirmed] = useState(0);
  const { t } = useTranslation(); 

  useEffect(() => {
    axiosInstance.get('/token/fetchTotalTokensSoldAndConfirmed')
      .then(response => {
        const totalTokensFormatted = new Intl.NumberFormat().format(response.data.totalTokensSoldAndConfirmed / (10 ** 18));
        setTotalTokensSoldAndConfirmed(totalTokensFormatted);
      })
      .catch(error => console.error(t("Failed to fetch total tokens sold and confirmed:"), error));
  }, []);

  return (
    <div>
      <h2>{t('Presale Tokens Sold:')} {totalTokensSoldAndConfirmed} {t('NXT')}</h2>
    </div>
  );
};

export default PresaleTokensSold;
