	import React, { useState, useEffect } from 'react';
	import { useParams } from 'react-router-dom';
	import { useMessage } from '../MessageContext';
	import { useTranslation } from 'react-i18next';

	import axiosInstance from '../axios';
	import Web3 from 'web3';
	import Presale from '../adminfiles/Presale.json';
	import NexTrustTokenContract from '../adminfiles/NexTrustToken.json'; 
	import TokenConfirmInstructionModal from './tokenConfirmInstructionModal'; 

	const web3 = new Web3(window.ethereum);
	const presaleContractAddress = process.env.REACT_APP_PRESALE_ADDRESS;
	const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;
	const mainWalletAddress = process.env.REACT_APP_TOKEN_NEXTRUST_MAIN_WALLET;

	const presaleContract = new web3.eth.Contract(Presale.abi, presaleContractAddress);
	const tokenContract = new web3.eth.Contract(NexTrustTokenContract.abi, tokenAddress);

const UserTransactionsList = () => {
  const [users, setUsers] = useState([]);
  const [detailsVisible, setDetailsVisible] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState({});
  const { transactionId } = useParams();
  const { setMessage } = useMessage();
  const { t } = useTranslation();

 useEffect(() => {
    const fetchTransactions = async () => {
      const userId = localStorage.getItem('userId');
      if (!userId) {
        return;
      }

      try {
        const response = await axiosInstance.get(`/token/fetchUserTransactions?userId=${userId}`);
        const transactions = response.data || [];
        setUsers([{ name: 'Current User', transactions }]);
        if (transactionId) {
          const transaction = transactions.find(tx => tx.transactionId === transactionId);
          if (transaction) {
            setSelectedTransaction(transaction);
            setModalVisible(true);
          }
        }
      } catch (error) {
      }
    };

    fetchTransactions();
  }, [transactionId]);

	
const cancelReservation = async (transactionId, userWallet) => {
  try {
    const accounts = await web3.eth.getAccounts();
    if (!accounts[0]) throw new Error(t('Wallet not connected'));

    await presaleContract.methods
      .cancelReservation(transactionId, userWallet)
      .send({ from: accounts[0] });

    const response = await axiosInstance.post('/token/updateTransactionStatus', {
      transactionId,
      status: 'canceled'
    });

    if (!response.data.success) throw new Error(t('Failed to update transaction status in database'));

    setMessage(t('Reservation canceled successfully'));

  } catch (error) {
    setMessage(t('Failed to cancel reservation. Check console for details.'));
  }
};
	
  const toggleDetailsVisibility = (transactionId) => {
    setDetailsVisible(prevState => ({
      ...prevState,
      [transactionId]: !prevState[transactionId]
    }));
  };


const toggleModal = (transaction) => {
  setSelectedTransaction(transaction);
  setModalVisible(true);
};


const closeModal = () => {
  setModalVisible(false); 
};

  return (
    <div>
	 {modalVisible && (
        <TokenConfirmInstructionModal transaction={selectedTransaction} closeModal={closeModal} />
      )}	
     <h1>{t('Your Transactions')}</h1>
      <p><br></br></p>
      <div className="transaction-container-header">
        <div className="transaction-detail">
          <h2>{t('Transaction ID:')}</h2>
        </div>
        <div className="transaction-detail">
          <h2>{t('NXT')}</h2>
        </div>
        <div className="transaction-detail">
          <h2>{t('USD')}</h2>
        </div>
        <div className="transaction-detail">
          <h2>{t('ETH')}</h2>
        </div>
        <div className="transaction-detail">
        </div>
      </div>

      {Array.isArray(users) && users.length > 0 ? (
        users.map((user) => (
          <div key={user._id}>
            {Array.isArray(user.transactions) && user.transactions.length > 0 ? (
              user.transactions.map((tx) => (
                <div className="transaction-container" key={tx.transactionId}>
                  <div className="transaction-detail">
                    <h2>{tx.transactionId}</h2>
                  </div>
                  {tx.status !== 'canceled' && (
                    <>
                      <div className="transaction-detail">
                        <p>{new Intl.NumberFormat().format(tx.tokensToReserve)}</p>
                      </div>
                      <div className="transaction-detail">
                        <p>{new Intl.NumberFormat().format(tx.usdAmount)}</p>
                      </div>
                      <div className="transaction-detail">
                        <p>{new Intl.NumberFormat().format(tx.ethRequired)}</p>
                      </div>
                    </>
                  )}
                  {tx.status === 'canceled' && (
                    <>
                      <div className="transaction-detail"></div>
                      <div className="transaction-detail"></div>
                      <div className="transaction-detail"><p>{t('Canceled')}</p></div>
                    </>
                  )}
                  <div className="transaction-detail">
                    {tx.status === 'pending' && (
                      <>
                        <button onClick={() => cancelReservation(tx.transactionId, user.walletAddress)}>{t('Cancel')}</button>
                        <button onClick={() => toggleModal(tx)}>{t('Finalize Purchase')}</button>
                      </>
                    )}
                    {tx.status !== 'canceled' && (
                      <button onClick={() => toggleDetailsVisibility(tx.transactionId)}>
                        {detailsVisible[tx.transactionId] ? t('Hide') : t('Show More')}
                      </button>
                    )}
                  </div>
                  {detailsVisible[tx.transactionId] && (
                    <div className="transaction-further-container">
                      <p>{t('Transaction Hash:')} {tx.txHash}</p>
                      <p>{t('Status:')} {tx.status}</p>
                      <p>{t('Tokens Distributed:')} {Intl.NumberFormat().format(tx.blockchainInfo?.tokensImmediate / (10 ** 18))}</p>
                      <p>{t('Tokens Deferred:')} {Intl.NumberFormat().format(tx.blockchainInfo?.tokensDeferred / (10 ** 18))}</p>
                      <p>{t('Purchase Time:')} {tx.blockchainInfo?.purchaseTime ? new Date(tx.blockchainInfo.purchaseTime * 1000).toLocaleString() : t('N/A')}</p>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p>{t('No transactions found.')}</p>
            )}
          </div>
        ))
      ) : (
        <p>{t('No transactions found.')}</p>
      )}
    </div>
  );
};

export default UserTransactionsList;