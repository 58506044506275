import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';
import './i18n';

function AppHeaderBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null); 
    const { t, i18n } = useTranslation();

  const handleLogout = (event) => {
    event.stopPropagation(); 
    localStorage.removeItem('token'); 
    localStorage.removeItem('companyId');
    navigate('/login');
  };

const toggleDropdown = (event) => {
  event.stopPropagation();
  setIsDropdownOpen(!isDropdownOpen);
};
	
	const handleLinkClick = () => {
    setIsDropdownOpen(false);
};

useEffect(() => {
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);

  return (
	  <>
    <div className="app-header-bar">
      <div className="app-header">
        <div className="app-logo">
            <NavLink to="/nextrust"><img src="../../images/logo/logo-nextrust.png" alt={t('NexTrust Logo')} /></NavLink>
        </div>
        <div className="app-welcome"></div>
        <div className="app-language-switcher"></div>
        <div className="app-user-name"></div>
        <div className="app-header-menu">
          <NavLink onClick={toggleDropdown}>
              <img src="../../images/icons/user_blue.png" alt={t('User - NexTrust')} />
          </NavLink>
               
        </div>
      </div>
    </div>
  <div className={`dropdown-content ${isDropdownOpen ? 'slide-in' : ''}`}  ref={dropdownRef}>
    <NavLink to="/nextrust/token-purchase" onClick={handleLinkClick}>{t('Get NXT')}</NavLink>
      <NavLink to="/nextrust/transactions" onClick={handleLinkClick}>{t('Transactions')}</NavLink>
      <NavLink to="/nextrust/update-wallet" onClick={handleLinkClick}>{t('Update Wallet')}</NavLink>
      <NavLink to="/nextrust/UpdateUser" onClick={handleLinkClick}>{t('Update User')}</NavLink>
                     <button onClick={handleLogout}>{t('Logout')}</button>
   <LanguageSwitcher currentLanguage={localStorage.getItem('language')} changeLanguage={(language) => i18n.changeLanguage(language)} />
           
         </div>

</>
  );
}

export default AppHeaderBar;
