	import React, { useState, useEffect } from 'react';
	import axiosInstance from '../axios';
	import Web3 from 'web3';
	import Presale from '../adminfiles/Presale.json';
	import NexTrustTokenContract from '../adminfiles/NexTrustToken.json'; 
	import { useTranslation } from 'react-i18next';

	const web3 = new Web3(window.ethereum);
	const presaleContractAddress = process.env.REACT_APP_PRESALE_ADDRESS;
	const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;
	const mainWalletAddress = process.env.REACT_APP_TOKEN_NEXTRUST_MAIN_WALLET;

const presaleContract = new web3.eth.Contract(Presale.abi, presaleContractAddress);
	const tokenContract = new web3.eth.Contract(NexTrustTokenContract.abi, tokenAddress);


const TransactionList = () => {
  const [users, setUsers] = useState([]);
  const [presaleEnded, setPresaleEnded] = useState(false);
  const [detailsVisible, setDetailsVisible] = useState({});
  const [userHasAccess, setUserHasAccess] = useState(false);
  const { t } = useTranslation();

 useEffect(() => {
    const checkUserAccess = async () => {
      const accounts = await web3.eth.requestAccounts();
      setUserHasAccess(accounts[0] === mainWalletAddress);
    };

    checkUserAccess();

    const fetchTransactions = async () => {
      if (!userHasAccess) return;
      try {
        const response = await axiosInstance.get('/token/fetchAllTransactions');
        if (response.data) {
          setUsers(response.data);
        } else {
          setUsers([]);
        }
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchTransactions();
  }, [userHasAccess]);

	
	 
	
const cancelReservation = async (transactionId, userWallet) => {
  try {
    const accounts = await web3.eth.getAccounts();
    if (!accounts[0]) throw new Error('Wallet not connected');
    
    await presaleContract.methods
      .cancelReservation(transactionId, userWallet)
      .send({ from: accounts[0] });

    const response = await axiosInstance.post('/token/updateTransactionStatus', {
      transactionId,
      status: 'canceled'
    });

    if (!response.data.success) throw new Error('Failed to update transaction status in database');

    alert('Reservation canceled successfully');

    // Refresh the transaction list to reflect the change
  } catch (error) {
    console.error('Error cancelling reservation:', error);
    alert('Failed to cancel reservation. Check console for details.');
  }
};

	  const toggleDetailsVisibility = (transactionId) => {
    setDetailsVisible(prevState => ({
      ...prevState,
      [transactionId]: !prevState[transactionId]
    }));
  };
	
	  if (!userHasAccess) {
    return <div>You do not have access to view this content.</div>;
  }

  return (
    <div>
      <h2>User Transactions</h2>
	 
 <div className="transaction-container-header">
	   <div className="transaction-detail">
            <h4>Transaction ID:</h4>
			</div>
<div className="transaction-detail">
            <p> NXT</p>
							  </div>
<div className="transaction-detail">
            <p>USD</p>
	  </div>
<div className="transaction-detail">
            <p>ETH</p>
			</div>
	  <div className="transaction-detail">
</div>
</div>

      {Array.isArray(users) && users.length > 0 ? (
  users.map((user) => (
    <div key={user._id}>
      <h3>{user.name}'s Transactions</h3>
	  
      {Array.isArray(user.transactions) && user.transactions.length > 0 ? (
        user.transactions.map((tx, index) => (
          <div className="transaction-container" key={index}>
  <div className="transaction-detail">
            <h4>{tx.transactionId}</h4>
			</div>
<div className="transaction-detail">
            <p>{new Intl.NumberFormat().format(tx.tokensToReserve)}</p>
							  </div>
<div className="transaction-detail">
            <p>{new Intl.NumberFormat().format(tx.usdAmount)}</p>
	  </div>
<div className="transaction-detail">
            <p>{new Intl.NumberFormat().format(tx.ethRequired)}</p>
			</div>
<div className="transaction-detail">

			{tx.status === 'pending' && (
				
              <button onClick={() => cancelReservation(tx.transactionId, user.walletAddress)}>Cancel</button>

            )}
		
			
                  <button onClick={() => toggleDetailsVisibility(tx.transactionId)}>
                    {detailsVisible[tx.transactionId] ? 'Show Less' : 'Show More'}
                  </button>
			</div>
			
            {detailsVisible[tx.transactionId] && tx.blockchainInfo && (
              <div className="transaction-further-container">
                <p>Type: {tx.blockchainInfo.eventType}</p>
                
                {tx.blockchainInfo.eventType === 'ReservationCancelled' ? (
			<div className="transaction-detail">

                  <p>Reservation Status: Cancelled</p>
			</div>
                ) : (
                  <>
	  <div className="transaction-detail">
            <p>Status: {tx.status}</p>
            </div>
<div className="transaction-detail">
                    <p>Tokens Transferred: {Intl.NumberFormat().format(tx.blockchainInfo.tokensImmediate / (10 ** 18) || 'N/A')}</p>
	  </div>
<div className="transaction-detail">
                    <p>Tokens Deferred: {Intl.NumberFormat().format(tx.blockchainInfo.tokensDeferred / (10 ** 18) || 'N/A')}</p>
	  </div>
<div className="transaction-detail">
                    <p>Purchase Time: {tx.blockchainInfo.confirmationTime ? new Date(tx.blockchainInfo.confirmationTime * 1000).toLocaleString() : tx.blockchainInfo.purchaseTime ? new Date(tx.blockchainInfo.purchaseTime * 1000).toLocaleString() : 'N/A'}</p>
	</div>

                  </>
                )}
              </div>
            )}
			
          </div>
        ))
      ) : (
        <p>No transactions found.</p>
      )}
    </div>
  ))
) : (
  <p>No users found.</p>
      )}
    </div>
  );
};

export default TransactionList;
