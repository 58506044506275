import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useMessage } from '../MessageContext';

function NexTrustContactPage() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [messageTo, setMessageTo] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('');
  const [offsetY, setOffsetY] = useState(0);
  const { setMessage } = useMessage();

useEffect(() => {
    const totalImages = 2;
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const imagePath = `${process.env.PUBLIC_URL}/images/backgrounds/nextrust${randomIndex}.jpg`;
    setBackgroundImage(imagePath);

    const handleScroll = () => setOffsetY(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
	
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('/homepage/contact', {
        email,
        name,
        messageTo
      });

      if (response.status === 200) {
    setMessage('Message sent successfully!');
        setEmail('');
        setName('');
        setMessageTo('');
      } else {
        setMessage('Failed to send email. Please try again later.');
      }
    } catch (error) {
      setMessage('Failed to send email. Please try again later.');
    }
  };

  return (
	  <>
	  
	  
	      <div className="nextrust-starting-page" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="overlay-content" style={{ top: `${offsetY * 0.3}px` }}>
      <form onSubmit={handleSubmit}>
      <h2>{t('Contact us')}</h2>
<p><br></br></p>
          <div className="form-group">
            <label>{t('name')}</label>
             <input
          type="text"
          placeholder="Your name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
          </div>
 <div className="form-group">
            <label>{t('your_email')}</label>
               <input
          type="email"
          placeholder="Your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
          </div>
          <div className="form-group">
            <label>{t('message')}</label>
             <textarea
          placeholder="Your message"
          value={messageTo}
          onChange={(e) => setMessageTo(e.target.value)}
          required
        />
          </div>
         <button type="submit">{t('send')}</button>

        </form>
      </div>
    </div>
</>
  );
}

export default NexTrustContactPage;
