	import React, { useState, useEffect } from 'react';
	import Web3 from 'web3';
	import PresaleContract from '../adminfiles/Presale.json';

	const presaleAbi = PresaleContract.abi;
	const mainWalletAddress = process.env.REACT_APP_TOKEN_NEXTRUST_MAIN_WALLET;

	const PreSaleStart = () => {
	  const [status, setStatus] = useState('');
	  const [web3, setWeb3] = useState(null);
	  const [contract, setContract] = useState(null);
	  const [accounts, setAccounts] = useState([]);
  const [userHasAccess, setUserHasAccess] = useState(false);

useEffect(() => {
    const initWeb3 = async () => {
      try {
        if (!window.ethereum) {
          throw new Error('MetaMask is not installed');
        }
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);

        const accounts = await web3Instance.eth.requestAccounts();
        if (accounts.length === 0) throw new Error('Failed to get accounts from MetaMask');
        setAccounts(accounts);
        
		   const mainWalletAddress = process.env.REACT_APP_TOKEN_NEXTRUST_MAIN_WALLET.toLowerCase();
        const hasAccess = accounts[0].toLowerCase() === mainWalletAddress;
        setUserHasAccess(hasAccess);
		  
		      if (!hasAccess) {
          setStatus('You do not have access to this page.');
          return;
        }

        console.log("Contract address from env:", process.env.REACT_APP_PRESALE_ADDRESS);
        const presaleContractInstance = new web3Instance.eth.Contract(presaleAbi, process.env.REACT_APP_PRESALE_ADDRESS);

        if (!presaleContractInstance) throw new Error('Failed to create contract instance');
        setContract(presaleContractInstance);
      } catch (error) {
        console.error(`Initialization failed: ${error.message}`, error);
        alert(`Initialization Error: ${error.message}`);
      }
    };

    initWeb3();
  }, []);

	  const startPresale = async () => {
		if (!contract || accounts.length === 0) {
		  const errorMessage = 'MetaMask is not connected or contract is not set';
		  console.error(errorMessage);
		  setStatus(errorMessage);
		  return;
		}

		try {
		  const receipt = await contract.methods.startPresale().send({ from: accounts[0] });
		  setStatus(`Presale started successfully: ${receipt.transactionHash}`);
		  console.log('Transaction receipt:', receipt);
		} catch (error) {
		  console.error('Error starting presale:', error);
		  setStatus(`Failed to start presale: ${parseErrorMessage(error)}`);
		}
	  };


	const advanceStage = async () => {
	  if (!contract || accounts.length === 0) {
		const errorMessage = 'MetaMask is not connected or contract is not set';
		console.error(new Date().toISOString(), errorMessage);
		setStatus(errorMessage);
		return;
	  }

	  try {
		const transactionDetails = {
		  from: accounts[0],
		  // Include more transaction details as necessary, such as gasLimit, gasPrice, etc.
		};
		console.log('Attempting to advance stage with transaction details:', transactionDetails);

		const receipt = await contract.methods.manualAdvanceStage().send(transactionDetails);
		console.log('Transaction receipt:', receipt);
		setStatus(`Stage advanced successfully: ${receipt.transactionHash}`);
	  } catch (error) {
		console.error('Error advancing stage at:', new Date().toISOString());
		console.error('Transaction attempt failed with accounts:', accounts);
		console.error('Contract attempted:', contract.options.address);
		console.error('Error details:', error);
		console.error('Error stack:', error.stack);

		const parsedError = parseErrorMessage(error);
		console.error('Parsed error message:', parsedError);
		setStatus(`Failed to advance stage: ${parsedError}`);


	  }
	};


	const parseErrorMessage = (error) => {
	  if (error.message.includes('user denied transaction signature')) {
		return 'Action was cancelled by the user.';
	  }

	  const knownErrorRegex = /revert(.*)/;
	  const match = error.message.match(knownErrorRegex);
	  if (match && match[1]) {
		return `Transaction failed: ${match[1].trim()}`;
		}

		return `Unexpected error occurred: ${error.message}`;
	  };

    
		const endPresale = async () => {
		if (!contract || accounts.length === 0) {
			setStatus('MetaMask is not connected or contract is not set');
			return;
		}

		try {
			const transactionDetails = {
				from: accounts[0]
				// Optional: Specify gasLimit and gasPrice as necessary
			};

			const receipt = await contract.methods.endPresale().send(transactionDetails);
			setStatus(`Presale ended successfully: ${receipt.transactionHash}`);
		} catch (error) {
			console.error('Error ending presale:', error);
			setStatus(`Failed to end presale: ${error.message}`);
		}
	};

		
  if (!userHasAccess) {
    return <div>You do not have access to this page.</div>;
  }
		
		
	  return (
		<div>
		  <button onClick={startPresale}>Start the Presale</button>
		  <button onClick={advanceStage}>Advance Stage</button>
				  <button onClick={endPresale}>End Presale</button>
		  {status && <p>{status}</p>}
		</div>
	  );
	};

	export default PreSaleStart;
