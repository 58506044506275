import React from 'react';
import { useMessage } from './MessageContext';

const MessagePopup = () => {
  const { message } = useMessage();

  return (
    <>
      {message && (
        <div className="message-popup">
          {message}
        </div>
      )}
    </>
  );
};

export default MessagePopup;
