import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios';
import { useMessage } from '../MessageContext';
import { useTranslation } from 'react-i18next';

const UpdateWallet = ({ onWalletUpdate }) => {
  const [walletAddress, setWalletAddress] = useState('');
  const { setMessage } = useMessage();
  const { t } = useTranslation();

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    axiosInstance.get(`/token/getWallet?userId=${userId}`)
      .then(response => {
        setWalletAddress(response.data.walletAddress);
      })
      .catch(error => {
        setMessage(t('Failed to fetch current wallet address.'));
      });
  }, [setMessage, t]);

  const updateWalletAddress = (e) => {
    e.preventDefault();
    const userId = localStorage.getItem('userId');

    axiosInstance.post('/token/updateWallet', { walletAddress, userId })
      .then(response => {
        setMessage(t('Wallet address updated successfully!'));
        onWalletUpdate();
      })
      .catch(error => {
        setMessage(t('Failed to update wallet address.'));
      });
  };

  return (
    <div>
      <h1>{t('Update Wallet Address')}</h1>
      <p>{t('Your current linked wallet address:')} <strong>{walletAddress || t('Not available')}</strong></p>
      <form onSubmit={updateWalletAddress} className="update-form">
        <div className="form-field">
          <input
            type="text"
            placeholder={t('Enter new wallet address')}
            value={walletAddress}
            onChange={(e) => setWalletAddress(e.target.value)}
          />
        </div>
        <button type="submit" className="update-button">{t('Update')}</button>
      </form>
      <p><br></br></p>
	<h2>{t('Important Notice')}</h2>
     <p className="disclaimer-text">{t('disclaimer_text_wallet')}</p>
       
    </div>
  );
};

export default UpdateWallet;
