import React, { useState, useEffect } from 'react';
import { useNavigate, Routes, Route, Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

function NexTrustBusinessModelPage() {
  const [backgroundImage, setBackgroundImage] = useState('');
  const [offsetY, setOffsetY] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const totalImages = 7;
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const imagePath = `${process.env.PUBLIC_URL}/images/backgrounds/nextrust${randomIndex}.jpg`;
    setBackgroundImage(imagePath);

    const handleScroll = () => setOffsetY(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <div className="nextrust-other-page" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="overlay-content" style={{ top: `${offsetY * 0.3}px` }}>
         <h1>{t('Our Business Model')}</h1>
<p>{t('Explore the Business Model of NexTrust: Driving Value in a Data-Driven World.')}</p>
          </div>
      </div>


  
<div className="business-model-section">
  <div className="content-section-homepage">
    <div className="grid-layout">
      <div className="grid-content-item center">
       <h1>{t('Explore Our Robust Business Model')}</h1>
        <p>{t('At the heart of our strategy, our business model is structured to foster growth, innovation, and customer satisfaction. Learn how our diverse revenue streams, strategic growth initiatives, and comprehensive access fees structure ensure sustainable development and a competitive advantage in the marketplace.')}</p>
        </div>
    </div>
    </div>
    </div>
<div className="business-model-section">
  <div className="content-section-homepage">
    <div className="grid-layout center">
 <h1>{t('Access Fees for Platform Services')}</h1>
</div>
    <div className="grid-layout grid-three">
       
        <div className="grid-item">
            <img src="../../images/images/businessmodel1.jpg" alt="Business Model of NexTrust" />

<strong>{t('Custom Information Gathering:')}</strong> {t('Tailored data collection services for industry-specific needs, including real-time feeds and bespoke research.')}</div>
           <div className="grid-item col-six">

            <img src="../../images/images/businessmodel2.jpg" alt="Business Model of NexTrust" />


<strong>{t('API Access:')}</strong> {t('Seamless integration of robust APIs providing validated and constantly updated data streams.')}</div>
           <div className="grid-item col-six">

            <img src="../../images/images/businessmodel3.jpg" alt="Business Model of NexTrust" />

<strong>{t('Blockchain Verification Services:')}</strong> {t('Blockchain solutions ensuring data authenticity and integrity, critical for finance and legal industries.')}</div>

</div>
</div>

<div className="business-model-section">
  <div className="content-section-homepage">
    <div className="grid-layout center">
 <h1>{t('Diverse Revenue Streams')}</h1>
</div>
    <div className="grid-layout grid-three">
         <div className="grid-item col-six">            <img src="../../images/images/businessmodel4.jpg" alt="Business Model of NexTrust" />

<strong>{t('Transaction Fees:')}</strong> {t('Fees charged on token transactions within our platform, promoting a vibrant ecosystem.')} </div>
           <div className="grid-item col-six">            <img src="../../images/images/businessmodel5.jpg" alt="Business Model of NexTrust" />
<strong>{t('Subscription Models:')}</strong> {t('Multiple tiers offering varying levels of service and features, from basic to enterprise solutions.')} </div>
         <div className="grid-item col-six">            <img src="../../images/images/businessmodel6.jpg" alt="Business Model of NexTrust" />
<strong>{t('Premium Services:')}</strong> {t('Advanced capabilities for bespoke needs, such as predictive analytics and custom development.')} </div>
      </div>
    </div>
</div>

<div className="business-model-section">
  <div className="content-section-homepage">
    <div className="grid-layout center">
 <h1>{t('Strategic Growth Initiatives')}</h1>
</div>
    <div className="grid-layout grid-two">

           <div className="grid-item col-six">            <img src="../../images/images/businessmodel7.jpg" alt="Business Model of NexTrust" />
<strong>{t('Partnerships and Integrations:')}</strong> {t('Collaborations with tech leaders and industry pioneers to expand our service offerings and access new markets.')}</div>
           <div className="grid-item col-six">            <img src="../../images/images/businessmodel8.jpg" alt="Business Model of NexTrust" />
<strong>{t('Educational and Consultative Services:')}</strong> {t('Workshops and seminars to assist companies in understanding and utilizing our platform effectively.')}</div>
      </div>
    </div>
</div>

<div className="business-model-section">
  <div className="content-section-homepage">
    <div className="grid-layout center">
      <div className="grid-content-item col-six center">
        <Link to="/register"><button className="action big">{t('Explore Our Services')}</button></Link>
      </div>
    </div>
  </div>
</div>
</div>


    </>
  );
}

export default NexTrustBusinessModelPage;
