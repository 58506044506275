import React, { useState, useEffect } from 'react';
import { useNavigate, Routes, Route, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function NexTrustProductPage() {
  const [backgroundImage, setBackgroundImage] = useState('');
  const [offsetY, setOffsetY] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const totalImages = 7;
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const imagePath = `${process.env.PUBLIC_URL}/images/backgrounds/nextrust${randomIndex}.jpg`;
    setBackgroundImage(imagePath);

    const handleScroll = () => setOffsetY(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <div className="nextrust-other-page" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="overlay-content" style={{ top: `${offsetY * 0.3}px` }}>
          <h1>{t('Our Product to build Trust')}</h1>
          <p>{t('Revolutionizing information management by combining community validation, AI precision, and seamless accessibility for reliable, real-world applications.')}</p>
         </div>
      </div>

      <div className="content-section-homepage">
        <div className="grid-layout">
          <div className="grid-content-item center">
            <h1>{t('Your Trusted Partner in Data Accuracy and Reliability')}</h1>
            <p>{t('At NexTrust, we are dedicated to transforming the landscape of data management by creating a robust platform that ensures the highest standards of data accuracy and reliability. Our innovative approach combines community-driven insights with cutting-edge artificial intelligence to validate data through a comprehensive, two-tiered process.')}</p>
          </div>
        </div>
      </div>

      <div className="content-section-homepage">
        <div className="grid-layout grid-three">
          <div className="grid-item span-two">
            <h1>{t('How NexTrust Works')}</h1>
            <h2>{t('Community-Driven Validation')}</h2>
            <p>{t('Our platform begins with peer review—every piece of data submitted by our users is carefully examined by other members of the NexTrust community. This human element ensures that the information is not only accurate but also practical and relevant to real-world applications.')}</p>
            <h2>{t('AI-Enhanced Precision')}</h2>
            <p>{t('Complementing the peer review, our advanced AI algorithms play a critical role. They are designed to detect inconsistencies, verify facts, and refine data input processes, providing an additional layer of precision and trust.')}</p>
            <h2>{t('Seamless Data Accessibility')}</h2>
            <p>{t('Once validated, this data is integrated into our secure API, making it easily accessible to our clients. Whether you need data for content creation, decision-making, or other purposes, our API delivers tailored information to meet your specific requirements.')}</p>
            <h2>{t('API Access')}</h2>
            <p>{t('Businesses can leverage our bespoke API for seamless integration and access to continuously updated, verified data streams—essential tools for making informed decisions swiftly.')}</p>
            <h2>{t('Marketplace')}</h2>
            <p>{t('Our specialized marketplace offers a secure environment where companies can request and obtain validated, customized research and data. This feature supports both data suppliers and consumers, fostering a community based on trust and reliability.')}</p>
          </div>
          <div className="grid-item">
            <img src="../../images/images/product_visual.jpg" alt="Visualization of NexTrust Process" />
          </div>
        </div>
      </div>

      <div className="content-section-homepage">
        <div className="grid-layout grid-three">
          <div className="grid-item col-six">
            <h1>{t('Blockchain Integration')}</h1>
            <p>{t('To enhance transparency and security, we incorporate blockchain technology to trace the origin of information and every step of its validation. Smart contracts are employed to automate and secure transactions within our platform, ensuring that all content agreements are upheld.')}</p>
          </div>
          <div className="grid-item col-six">
            <h1>{t('Token Economy')}</h1>
            <p>{t('NexTrust employs a token economy to encourage participation and contribution within our ecosystem. Users earn NXT tokens for their contributions, which can then be used within the platform or traded. This system not only fuels our ecosystem but also ensures a vibrant, interactive community.')}</p>
          </div>
          <div className="grid-item col-six">
            <h1>{t('Join Us')}</h1>
            <p>{t('Discover the future of data management with NexTrust. Join our platform today to contribute to and benefit from a world where information is accurate, reliable, and easily accessible. Register now to be part of our presale event or learn more about our features and how they can benefit your business.')}</p>
           </div>
        </div>
      </div>

      <div className="content-section-homepage">
        <div className="grid-layout grid-one center">
           
              <Link to="/register"><button className="action big">{t('Become part of the Revolution')}</button></Link>
       
        </div>
      </div>
    </>
  );
}

export default NexTrustProductPage;
