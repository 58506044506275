import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios';
import Web3 from 'web3';
import { useTranslation } from 'react-i18next';

function TotalTokenSupply() {
  const [totalSupply, setTotalSupply] = useState('');
  const { t } = useTranslation();

useEffect(() => {
  axiosInstance.get('/token/totalSupply')
    .then(response => {
      const supplyInWei = response.data.totalSupply;
      const supplyInTokens = Web3.utils.fromWei(supplyInWei, 'ether');
      const formattedSupply = new Intl.NumberFormat().format(supplyInTokens);
      setTotalSupply(formattedSupply);
    })
    .catch(error => {

      if (error.response) {
         } else if (error.request) {
        } else {
         }

      if (error.config) {
      }
    });
}, []);

  return (
        <>{t('Total Supply:')} {totalSupply} {t('NXT')}</>
	     );
}

export default TotalTokenSupply;
