import React, { useState } from 'react';
import axiosInstance from '../axios';

function ShowTokenBalance() {
    const [walletAddress, setWalletAddress] = useState('');
    const [balance, setBalance] = useState('');

    const fetchTokenBalance = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.get(`/token/tokenBalance/${walletAddress}`);
            setBalance(response.data.balance);
        } catch (error) {
            alert("Failed to fetch token balance");
            console.error(error);
        }
    };

    return (
        <div>
            <h2>Check NexTrustToken Balance</h2>
            <form onSubmit={fetchTokenBalance}>
                <label>
                    Wallet Address:
                    <input 
                        type="text" 
                        value={walletAddress}
                        onChange={(e) => setWalletAddress(e.target.value)}
                        required 
                    />
                </label>
                <button type="submit">Check Balance</button>
            </form>
            {balance && <p>Balance: {balance} NexTrustTokens</p>}
        </div>
    );
}

export default ShowTokenBalance;
