import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './NexTrust';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();

// Register service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    console.log('Attempting to register service worker');

    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        console.log('ServiceWorker registration successful with scope:', registration.scope);

        // Additional service worker lifecycle events
        registration.onupdatefound = () => {
          console.log('ServiceWorker update found');
          const installingWorker = registration.installing;
          installingWorker.onstatechange = () => {
            console.log('ServiceWorker state changed to:', installingWorker.state);
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                console.log('New or updated ServiceWorker installed.');
              } else {
                console.log('ServiceWorker installed for the first time.');
              }
            }
          };
        };
      })
      .catch(error => {
        console.error('ServiceWorker registration failed:', error);
      });

    navigator.serviceWorker.ready.then(registration => {
      console.log('ServiceWorker is ready:', registration);
    });
  });
}
