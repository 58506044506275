import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
 import TotalTokenSupply from '../token/totalTokenSupply';

function NexTrustDisclaimer() {
  const [backgroundImage, setBackgroundImage] = useState('');
  const [offsetY, setOffsetY] = useState(0);
  const { t } = useTranslation();
    const presaleAddress = process.env.REACT_APP_PRESALE_ADDRESS;

  useEffect(() => {
    const totalImages = 7;
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const imagePath = `${process.env.PUBLIC_URL}/images/backgrounds/nextrust${randomIndex}.jpg`;
    setBackgroundImage(imagePath);

    const handleScroll = () => setOffsetY(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
	
const formatTextWithNewlines = (text) => {
    return text.split('\n').map((item, i) => (
      <React.Fragment key={i}>
        {item}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <>
 <div className="nextrust-other-page" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="overlay-content" style={{ top: `${offsetY * 0.3}px` }}>
          <h1>{t('purchase_agreement')}</h1>
         
         </div>
      </div>


<div className="content-section-homepage">
	<div className="grid-content-item col-six">
		<p>{t('purchase_agreement_intro')}</p>
	</div>
</div>

<div className="content-section-homepage">
	<div className="grid-content-item col-six">
		<h1>{t('structure_terms_headline')}</h1>
<h2>{t('Deal Structure')}</h2>
<p><TotalTokenSupply /></p>
          <p>{formatTextWithNewlines(t('Deal Structure Text'))}</p>
<p><br></br></p>

<h2>{t('Buy with MetaMask')}</h2>
          <p>{formatTextWithNewlines(t('MetaMask Text'))}</p>
<p><br></br></p>

<h2>{t('Reserve and pay later')}</h2>
          <p>{formatTextWithNewlines(t('Reserve Text'))}</p>

<p><br></br></p>
          <p>{formatTextWithNewlines(t('resevere and purchase general text'))}</p>
<p><br></br></p>
	</div>


<div className="grid-content-item col-six">

  <h2>{t('The Agreement')}</h2>

  <p>{t('agreementCertification')}</p>

  <p>{t('paymentInstructions')}</p>
<p><br></br></p>

</div>

<div className="grid-content-item col-six">

  <h2>{t('Terms Purchase')}</h2>

  <p>{t('TemmsofPurchaseText1')} {presaleAddress} {t('TemmsofPurchaseText2')}</p>
<p><br></br></p>

</div>	

</div>


<div className="content-section-homepage">
	<div className="grid-content-item col-six">
		<h1>{t('termsDefinitionsHeadline')}</h1>
          <p>{formatTextWithNewlines(t('termsDefinitions'))}</p>
	</div>
</div>

<div className="content-section-homepage">
	<div className="grid-content-item col-six">
		<h1>{t('TermofSales')}</h1>
	</div>

	<div className="grid-content-item col-six">
		<h2>{t('ReservationRights')}</h2>
        <p>{formatTextWithNewlines(t('ReservationRightsText'))}</p>
<p><br></br></p>

</div>

<div className="grid-content-item col-six">
		<h2>{t('deliveryofInvestment')}</h2>
        <p>{formatTextWithNewlines(t('deliveryofInvestmenttext'))}</p>
<p><br></br></p>

	</div>

<div className="grid-content-item col-six">
		<h2>{t('deliveryofToken')}</h2>
        <p>{formatTextWithNewlines(t('deliveryofTokenText'))}</p>
<p><br></br></p>

	</div>


<div className="grid-content-item col-six">
		<h2>{t('vestingScheduleHeadline')}</h2>
        <p>{formatTextWithNewlines(t('vestingSchedule'))}</p>
<p><br></br></p>
	</div>

<div className="grid-content-item col-six">
		<h2>{t('terminationHeadline')}</h2>
        <p>{formatTextWithNewlines(t('terminationText'))}</p>
	</div>

</div>



<div className="content-section-homepage">
	<div className="grid-content-item col-six">
		<h1>{t('Company Representations')}</h1>
	</div>

<div className="grid-content-item col-six">
		<h2>{t('Corporate Entity')}</h2>
        <p>{formatTextWithNewlines(t('Corporate Entity Text'))}</p>
<p><br></br></p>

	</div>

<div className="grid-content-item col-six">
		<h2>{t('Execution, delivery, and performance')}</h2>
        <p>{formatTextWithNewlines(t('Execution, delivery, and performance Text'))}</p>
	<p><br></br></p>
</div>

<div className="grid-content-item col-six">
		<h2>{t('Legal Obligation')}</h2>
        <p>{formatTextWithNewlines(t('Legal Obligation Text'))}</p>
	<p><br></br></p>
</div>

<div className="grid-content-item col-six">
		<h2>{t('Company Affirmation')}</h2>
        <p>{formatTextWithNewlines(t('Company Affirmation Text'))}</p>
	</div>

</div>


<div className="content-section-homepage">
	<div className="grid-content-item col-six">
		<h1>{t('Purchaser Representations')}</h1>
	</div>

<div className="grid-content-item col-six">
		<h2>{t('Purchaser Agreement')}</h2>
        <p>{formatTextWithNewlines(t('Purchaser Agreement Text'))}</p>
		<p><br></br></p>
</div>

<div className="grid-content-item col-six">
		<h2>{t('Not Financial Advice')}</h2>
        <p>{formatTextWithNewlines(t('Not Financial Advice Text'))}</p>
		<p><br></br></p>
</div>

<div className="grid-content-item col-six">
		<h2>{t('Purchaser Aware')}</h2>
        <p>{formatTextWithNewlines(t('Purchaser Aware Text'))}</p>
		<p><br></br></p>
</div>

<div className="grid-content-item col-six">
		<h2>{t('Third Parties')}</h2>
        <p>{formatTextWithNewlines(t('Third Parties Text'))}</p>
		<p><br></br></p>
</div>

</div>

<div className="content-section-homepage">
	<div className="grid-content-item col-six">
		<h1>{t('Disclaimer of Warranties')}</h1>
        <p>{formatTextWithNewlines(t('Disclaimer of Warranties Text'))}</p>
	</div>
</div>


<div className="content-section-homepage">
	<div className="grid-content-item col-six">
		<h1>{t('Miscellaneous')}</h1>
	</div>

<div className="grid-content-item col-six">
		<h1>{t('Amendments')}</h1>
        <p>{formatTextWithNewlines(t('Amendments Text'))}</p>
		<p><br></br></p>
	</div>

<div className="grid-content-item col-six">
		<h1>{t('Receipt of Notices')}</h1>
        <p>{formatTextWithNewlines(t('Receipt of Notices Text'))}</p>
		<p><br></br></p>
	</div>

<div className="grid-content-item col-six">
		<h1>{t('Limitation of Representation')}</h1>
        <p>{formatTextWithNewlines(t('Limitation of Representation Text'))}</p>
		<p><br></br></p>
	</div>

<div className="grid-content-item col-six">
		<h1>{t('Assignment')}</h1>
        <p>{formatTextWithNewlines(t('Assignment Text'))}</p>
		<p><br></br></p>
	</div>

<div className="grid-content-item col-six">
		<h1>{t('Intellectual Property')}</h1>
        <p>{formatTextWithNewlines(t('Intellectual Property Text'))}</p>
		<p><br></br></p>
	</div>

<div className="grid-content-item col-six">
		<h1>{t('Entire Agreement')}</h1>
        <p>{formatTextWithNewlines(t('Entire Agreement Text'))}</p>
		<p><br></br></p>
	</div>

<div className="grid-content-item col-six">
		<h1>{t('Severability')}</h1>
        <p>{formatTextWithNewlines(t('Severability Text'))}</p>
		<p><br></br></p>
	</div>

<div className="grid-content-item col-six">
		<h1>{t('Governing Law')}</h1>
        <p>{formatTextWithNewlines(t('Governing Law Text'))}</p>
	</div>

</div>

<div className="content-section-homepage">
	<div className="grid-content-item col-six">
		<h1>{t('comesIntoEffect')}</h1>
	</div>
</div>


</>
);
}

export default NexTrustDisclaimer;



