import React, { useState, useEffect } from 'react';
import axiosInstance from '../axios';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';

const TokenConfirmInstructionModal = ({ transaction, closeModal }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
	const { t } = useTranslation();

    const presaleAddress = process.env.REACT_APP_TOKEN_NEXTRUST_MAIN_WALLET;

    useEffect(() => {
        if (!transaction || !transaction.transactionId) {
            setError('Transaction details are not provided.');
            setIsLoading(false);
            return;
        }

        try {
            setIsLoading(false);
        } catch (error) {
            const errorMessage = 'Failed to fetch transaction details.';
            setError(errorMessage);
            setIsLoading(false);
            console.error(`Error: ${errorMessage}`, error);
        }
    }, [transaction]);

    if (isLoading) return <div className="loading-overlay">Loading transaction details...</div>;
    if (error) return <div className="message-popup">{error}</div>;

	    const walletAddressUsed = transaction.walletAddressUsed || '0'; 

    const ethAmount = transaction.ethRequired || '0'; 
	    const weiAmount = (transaction.ethRequired || 0) * 1e18;

    const ethereumUri = `ethereum:${presaleAddress}?value=${weiAmount.toString()}&transactionId=${transaction.transactionId}`;

    return (
        <div className="popup-overlay">
            <div className="popup">
                <button className="close-button" onClick={closeModal}>×</button>
                <h1>{t('To Confirm Your Reseravtion:')}</h1>
		<div className="transaction-info">
		<p>{t('Please send:')}: <strong>{ethAmount} {t('ETH')}</strong></p>
		<p>{t('To the following contract address:')} <strong>{presaleAddress}</strong></p>
		<p>{t('Add the Transaction ID as a reference:')} <strong>{transaction.transactionId}</strong></p>
		<h1>{t('important')}</h1>
		<p>{t('Send the tokens from this wallet:')} <strong>{walletAddressUsed}</strong></p>
               	</div>
                 <div className="qr-container">
                    <h2>{t('Or, scan this QR Code with your Ethereum Wallet to Pay')}</h2>
                    <QRCode value={ethereumUri} size={120} style={{ margin: 'auto', display: 'block', maxWidth: '100%' }} />
                </div>
		<p><br></br></p>
		<p className="disclaimer-text">{t('important')} {t('disclaimer')}: {t('disclaimer_send_eth')}</p>
            </div>
        </div>
    );
};

export default TokenConfirmInstructionModal;
