import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios';
import { useMessage } from '../MessageContext';
import { useTranslation } from 'react-i18next';

const UserWallet = () => {
  const [walletAddress, setWalletAddress] = useState('');
  const { message, setMessage } = useMessage(); 
  const { t } = useTranslation();

  useEffect(() => {
    const userId = localStorage.getItem('userId');

    axiosInstance.get(`/token/getWallet?userId=${userId}`)
      .then(response => {
        setWalletAddress(response.data.walletAddress);
      })
      .catch(error => {
		setMessage(t('Failed to fetch current wallet address.'));
      });
  }, []);

  return (
    <div>
      <h2>{t('your_wallet')}</h2>
      <p className="disclaimer-text">{walletAddress || 'Not available'}</p>
	 
    </div>
  );
};

export default UserWallet;
