import React, { useState } from 'react';
import axios from '../axios.js';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useMessage } from '../MessageContext';

const ForgotPasswordModal = ({ onClose }) => {
    const [email, setEmail] = useState('');
    const [isError, setIsError] = useState(false);
    const { t } = useTranslation();
    const { setMessage } = useMessage();

const handleForgotPassword = async () => {
    const currentLanguage = i18n.language;
    const data = {
        email: email, 
        language: currentLanguage,
    };

    try {
        await axios.post('/users/forgot-password', data);
        setMessage(t('password_reset_success_message'));
        setIsError(false); 
    } catch (error) {
        setMessage(error.response?.data?.message || t('error_message'));
        setIsError(true);
    }
};


    const handleChange = (e) => {
        setEmail(e.target.value);
       
    };

    return (
        <div className="popup-overlay">
            <div className="popup">
                <button className="close-button" onClick={onClose}>X</button>
                <h1>{t('forgot_password')}</h1><br></br>
                <input
                    type="email"
                    placeholder={t('your_email')}
                    value={email}
                    onChange={handleChange}
                    required
                />
                <button onClick={handleForgotPassword} className="save">{t('reset_password_button')}</button>
            </div>
        </div>
    );
};

export default ForgotPasswordModal;
