import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function VerifyEmailUpdate() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [verificationAttempted, setVerificationAttempted] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (token && !verificationAttempted) {
      setVerificationAttempted(true);
      axiosInstance.get(`/logedinusers/verify/${token}`)
        .then(response => {
          navigate('/nextrust/UpdateUser'); 
        })
        .catch(error => {
          const errorMessage = error.response?.data?.message || error.message || "Unknown error occurred.";
          navigate('/error'); 
        });
    }
  }, [token, navigate, verificationAttempted]);

  return (
    <div>
				{t('Verifying your email')}
    </div>
  );
}

export default VerifyEmailUpdate;
