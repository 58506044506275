	import React, { useState, useEffect } from 'react';
	import Web3 from 'web3';
	import InitialNexTrustDistribution from '../adminfiles/InitialNexTrustDistribution.json';
	import NexTrustTokenContract from '../adminfiles/NexTrustToken.json'; 

	const web3 = new Web3(window.ethereum);
	const distributionContractAddress = process.env.REACT_APP_DISTRIBUTION_CONTRACT_ADDRESS;
	const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;
	const mainWalletAddress = process.env.REACT_APP_TOKEN_NEXTRUST_MAIN_WALLET;

	const distributionContract = new web3.eth.Contract(InitialNexTrustDistribution.abi, distributionContractAddress);
	const tokenContract = new web3.eth.Contract(NexTrustTokenContract.abi, tokenAddress);


	function DistributionForm() {
	  const [address, setAddress] = useState('');
	  const [amount, setAmount] = useState('');
	  const [allocations, setAllocations] = useState([]);
	  const [message, setMessage] = useState('');
  const [userHasAccess, setUserHasAccess] = useState(false);

		
		
  useEffect(() => {
    const checkAccess = async () => {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const hasAccess = accounts[0].toLowerCase() === mainWalletAddress.toLowerCase();
        setUserHasAccess(hasAccess);
      } catch (error) {
        console.error('Error accessing wallet:', error);
        setMessage('Failed to access wallet. Check console for details.');
      }
    };

    checkAccess();
  }, []);


		
	

	const handleAddressChange = (e) => {
	  try {
		const newAddress = e.target.value;
		setAddress(newAddress);
	  } catch (error) {
	  }
	};

	const handleAmountChange = (e) => {
	  try {
		const newAmount = e.target.value;
		setAmount(newAmount);
	  } catch (error) {
	  }
	};


	 const handleAddAllocation = () => {
	  try {
		if (!address || !amount) {
		  throw new Error("Both address and amount are required.");
		}

		const newAllocation = { address, amount };
		  setAllocations([...allocations, newAllocation]);
  setAddress('');
  setAmount('');

	  } catch (error) {
	  }
	};


	const checkPreconditions = async () => {
	  try {

		const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
		const account = mainWalletAddress;
		const balance = await tokenContract.methods.balanceOf(mainWalletAddress).call();
		const totalAmountToDistribute = allocations.reduce((acc, curr) => acc + parseFloat(curr.amount), 0);

		  if (parseFloat(web3.utils.fromWei(balance, 'ether')) < totalAmountToDistribute) {
		  throw new Error("Insufficient token balance for distribution.");
		}
		return account; 
	  } catch (error) {
		throw error;
	  }
	};


	async function approveTokenDistribution(totalAmount) {
    const distributionAmount = web3.utils.toWei(totalAmount.toString(), 'ether');
    try {
        const accounts = await web3.eth.getAccounts();
        const tokenOwnerAccount = accounts[0]; // Assuming the token owner is the first account
        await tokenContract.methods.approve(distributionContractAddress, distributionAmount).send({ from: tokenOwnerAccount });
    } catch (error) {
	}
}

const handleSubmit = async (e) => {
    e.preventDefault();

    // Calculate total amount to distribute
    const totalAmountToDistribute = allocations.reduce((acc, curr) => acc + parseFloat(curr.amount), 0);

    // First, approve the total amount to distribute
    await approveTokenDistribution(totalAmountToDistribute);

    // Then, proceed with the distribution
    try {
        const account = await checkPreconditions();
        if (allocations.length === 0) {
            throw new Error("At least one allocation is required.");
        }

        const addresses = allocations.map(allocation => allocation.address);
        const amounts = allocations.map(allocation => web3.utils.toWei(allocation.amount, 'ether'));
        
        // Sending the distribute tokens transaction
        await distributionContract.methods.distributeTokens(addresses, amounts).send({ from: account });

        setMessage('Tokens distributed successfully!');
        setAllocations([]); 
    } catch (error) {
        setMessage(`Error: ${error.message}`);
    }
};

  if (!userHasAccess) {
    return <div>You do not have access to this page.</div>;
  }
		

	  return (
		<div>
		  <h2>Token Distribution</h2>
		  <div>
			<label>
			  Address:
			  <input 
				type="text" 
				value={address}
				onChange={handleAddressChange}
				required 
			  />
			</label>
			<label>
			  Amount:
			  <input 
				type="number" 
				value={amount}
				onChange={handleAmountChange}
				required 
			  />
			</label>
			<button type="button" onClick={handleAddAllocation}>Add</button>
		  </div>
		  <form onSubmit={handleSubmit}>
			<button type="submit">Submit</button>
		  </form>
		  {allocations.length > 0 && (
			<ul>
			  {allocations.map((alloc, index) => (
				<li key={index}>{`Address: ${alloc.address}, Amount: ${alloc.amount}`}</li>
			  ))}
			</ul>
		  )}
		  <p>{message}</p>
		</div>
	  );
	}

	export default DistributionForm;
