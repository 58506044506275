import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios';
import { useTranslation } from 'react-i18next';

const PresaleDetails = () => {
  const [stageDetails, setStageDetails] = useState({});
  const { t } = useTranslation();

useEffect(() => {
  axiosInstance.get('/token/presaleDetails')
   .then(response => {
  const { tokensAvailable, usdPerToken, durationDays, startTimestamp, isActive, currentStageIndex } = response.data;
  const tokensAvailableInEther = tokensAvailable / (10 ** 18);
  
  const formattedTokensAvailable = new Intl.NumberFormat().format(tokensAvailableInEther.toFixed(0));
  const formattedUsdPerToken = (usdPerToken / 1000000).toFixed(6);
  const formattedUsdPerMillionTokens = new Intl.NumberFormat().format((usdPerToken * 1e6).toFixed(2));

  const startDate = new Date(startTimestamp * 1000).toLocaleString();

  const detailsObject = {
    tokensAvailable: formattedTokensAvailable,
    usdPerToken: formattedUsdPerToken,
    usdPerMillionTokens: formattedUsdPerMillionTokens,
    durationDays,
    startTimestamp: startDate,
    isActive,
    currentStageIndex
  };
  setStageDetails(detailsObject);
})

    .catch(error => console.error("Failed to fetch presale details:", error));
}, []);




  return (
    <div>
      {stageDetails.isActive ? (
        <div>
	   <p>{t('Start Date:')} {stageDetails.startTimestamp}</p>
	  <p>{t('Stage:')} {stageDetails.currentStageIndex}</p>
  <p>{t('Tokens available in current stage:')} {stageDetails.tokensAvailable} NXT</p>
  <p>{t('USD per Token:')} ${stageDetails.usdPerToken}</p>
</div>

      ) : (
        <p>{t('Presale not active yet!')}</p>
      )}
    </div>
  );
};

export default PresaleDetails;
