import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = ({ currentLanguage, changeLanguage }) => {
  return (
      <select value={currentLanguage} onChange={(e) => changeLanguage(e.target.value)}>
        <option value="en">English</option>
        <option value="de">Deutsch</option>
 </select>
  );
};

export default LanguageSwitcher;
