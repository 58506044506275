import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import axiosInstance from '../axios';
import Presale from '../adminfiles/Presale.json';

const web3 = new Web3(window.ethereum);

const ClaimTokens = () => {
  const [claimStatus, setClaimStatus] = useState({ claiming: false, success: false, error: '' });
  const [currentWeek, setCurrentWeek] = useState(0);
  const [claimablePercent, setClaimablePercent] = useState(0);
  const [totalDeferredTokens, setTotalDeferredTokens] = useState(0);
  const [claimableTokens, setClaimableTokens] = useState(0);
  const [totalTransferredTokens, setTotalTransferredTokens] = useState(0);
  const [presaleEndTime, setPresaleEndTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(Math.floor(Date.now() / 1000));

  const presaleContractAddress = process.env.REACT_APP_PRESALE_ADDRESS;
  const presaleContract = new web3.eth.Contract(Presale.abi, presaleContractAddress);

  useEffect(() => {
    const fetchClaimData = async () => {
      try {
        const endTime = await presaleContract.methods.presaleEndTime().call();
        setPresaleEndTime(Number(endTime));
        const timeNow = Math.floor(Date.now() / 1000);
        setCurrentTime(timeNow);

        if (presaleEndTime > 0 && currentTime > presaleEndTime) {
          const elapsedTime = currentTime - presaleEndTime;
          const week = Math.floor(elapsedTime / (7 * 24 * 60 * 60));
          const percentage = await presaleContract.methods.getClaimablePercentage(week + 1).call();

          setCurrentWeek(week + 1);
          setClaimablePercent(Number(percentage));

        }
      } catch (error) {
        setClaimStatus({ ...claimStatus, error: error.message });
      }
    };

    fetchClaimData();
  }, [claimStatus, presaleContract, presaleEndTime, currentTime]);

  useEffect(() => {
    const fetchTokenSummary = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const response = await axiosInstance.get(`/token/fetchTokenSummaryForUser/${userId}`);
        const { totalImmediateTokens, totalDeferredTokens: fetchedTotalDeferredTokens, totalTransferredTokens } = response.data;

        setTotalDeferredTokens(fetchedTotalDeferredTokens);
        setTotalTransferredTokens(totalTransferredTokens);
      } catch (error) {
      }
    };

    fetchTokenSummary();
  }, []);

  const claimTokens = async () => {
    setClaimStatus({ claiming: true });

    try {
      const accounts = await web3.eth.getAccounts();
      if (accounts.length === 0) throw new Error("Ethereum account not found. Please ensure your wallet is connected.");
      
      await presaleContract.methods.claimDeferredTokens().send({ from: accounts[0] })
        .on('receipt', (receipt) => {
          setClaimStatus({ claiming: false, success: true, error: '' });
        })
        .on('error', (error) => {
          setClaimStatus({ claiming: false, success: false, error: error.message });
        });
    } catch (error) {
      setClaimStatus({ claiming: false, success: false, error: error.message });
    }
  };

  return (
    <div>
{currentWeek === 0 ? (
        <div>
          <h1>Token Claim and Airdrop Schedule</h1>
          <p>As soon as our presale concludes, you can start claiming your tokens here. Notifications will alert you when each phase begins.</p>
          <ul>
            <li>Week 1: Claim 10% of your tokens.</li>
            <li>Week 2: Claim an additional 30%, totaling 40%.</li>
            <li>Week 3: Claim up to 60% of your tokens.</li>
            <li>Week 4 and beyond: All remaining tokens (100%) are claimable.</li>
          </ul>
          <p>Following the claim process, all remaining tokens will be airdropped to your account based on the following schedule:</p>
          <ul>
            <li>Week 1 of Airdrop: Claim 10% of your airdrop allocation.</li>
            <li>Week 2 of Airdrop: Claim an additional 30%, totaling 40%.</li>
            <li>Week 3 of Airdrop: Claim up to 60%.</li>
            <li>Week 4 of Airdrop: Claim any remaining airdrop tokens (100%).</li>
          </ul>
        </div>
      ) : (
        <div>
          <p>Current Week: {currentWeek}</p>
          <p>Claimable Percentage: {claimablePercent}%</p>
          <p>Total Deferred Tokens: {totalDeferredTokens}</p>
          <p>Claimable Tokens: {claimableTokens}</p>
          <p>Total Transferred Tokens: {totalTransferredTokens}</p>
          <button onClick={claimTokens} disabled={claimStatus.claiming}>
            {claimStatus.claiming ? 'Claiming...' : 'Claim Tokens'}
          </button>
          {claimStatus.error && <p>Error: {claimStatus.error}</p>}
        </div>
      )}
    </div>
  );
};

export default ClaimTokens;
