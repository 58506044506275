import React, { useState, useEffect } from 'react';
import { useNavigate, Routes, Route, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function NexTrustStartingPage() {
  const [backgroundImage, setBackgroundImage] = useState('');
  const [offsetY, setOffsetY] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const totalImages = 7;
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const imagePath = `${process.env.PUBLIC_URL}/images/backgrounds/nextrust${randomIndex}.jpg`;
    setBackgroundImage(imagePath);

    const handleScroll = () => setOffsetY(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
          <div className="nextrust-starting-page" style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: `50% ${offsetY * 0.5}px`, 
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed'
      }}>
        <div className="overlay-content" style={{ top: `${offsetY * 0.4}px` }}>
          <h1>{t('NexTrust - The Future of Trust')}</h1>
          <p>{t('Redefining trust in the digital age with innovative blockchain solutions and meticulous human oversight, ensuring every piece of information is reliable and verifiable.')}</p>
          <div className="buttons">
            <Link to="/register"><button className="btn">{t('Shape the Future')}</button></Link>
          </div>
        </div>
      </div>


<div className="content-section-homepage">
        <div className="grid-layout grid-three">
          <div className="grid-content-item col-six center">
            <h1>{t('Trust Reimagined')}</h1>
            <h2>{t('Building Reliable Foundations')}</h2>
          </div>
          <div className="grid-content-item col-six">
            <p>{t('NexTrust integrates advanced blockchain technology and a human-driven verification process to ensure the authenticity and accuracy of information.')}</p>
          </div>

 <div className="grid-content-item col-six">
         <h1>{t('The Journey Just Begun')}</h1>
            <p>{t('Our journey has just begun. Learn now what we aim to do and how we want to achieve a better world.')}</p>
          </div>

          <div className="grid-item col-two center">
            <h2>{t('Blockchain Technology')}</h2>
            <div className="grid-content-item col-two center">
              <div className="screen-container">
                <img src="../../images/images/trust-in-blockchain.jpg" alt="Blockchain Technology"/>
              </div>
            </div>
            <p>{t('Employing blockchain to provide an immutable record of all information validations, enhancing traceability and security.')}</p>
          </div>

          <div className="grid-item col-two center">
            <h2>{t('Human Expertise')}</h2>
            <div className="grid-content-item col-two center">
              <div className="screen-container">
                <img src="../../images/images/trust-in-humans.jpg" alt="Human Verification Process"/>
              </div>
            </div>
            <p>{t('Combining AI with expert human oversight to ensure that each piece of information is accurate and reliable.')}</p>
          </div>

          <div className="grid-item col-two center">
            <h2>{t('Token Economy')}</h2>
            <div className="grid-content-item col-two center">
              <div className="screen-container">
                <img src="../../images/images/token-economy.jpg" alt="Token Economy"/>
              </div>
            </div>
            <p>{t('Implementing a token economy to incentivize contributions and transactions within the NexTrust ecosystem.')}</p>
          </div>

         
        </div>
      </div>

  
<div className="problem-solution-section">
  <div className="content-section-homepage">
    <div className="grid-layout grid-one">
      <div className="grid-content-item col-twelve">
        <h1>{t('The Information Trust Crisis')}</h1>
        <p>{t('In a world inundated with unchecked data, the reliability of information has plummeted, creating critical challenges for decision-makers at all levels.')}</p>
<p><br></br></p>
      </div>
    </div>
    <div className="grid-layout grid-two">
      <div className="grid-item">
        <h2>{t('The Problem')}</h2>
        <p>{t('The reliance on unverified information can have serious repercussions. With the rise of AI, the challenge has intensified, leading to a trust deficit in critical data. Users often accept AI-generated information without questioning its accuracy, while the origins of data remain hidden, leading to misinformation.')}</p>
      </div>
      <div className="grid-item">
        <h2>{t('Our Solution')}</h2>
        <p>{t('NexTrust is committed to reversing this trend by establishing a robust framework that ensures the reliability of information. Our platform leverages cutting-edge technology to validate sources and enhance transparency, enabling users to make informed decisions based on trustworthy data.')}</p>
      </div>
    </div>
  </div>
    </div>



<div className="vision-mission-goals-section">
  <div className="content-section-homepage">
    <div className="grid-layout">
      <div className="grid-content-item center">
        <h1>{t('Our Vision, Mission, and Goals')}</h1>
        <h2>{t('Discover the core principles and objectives that drive NexTrust.')}</h2>
      </div>
    </div>
    <div className="grid-layout grid-three">
      <div className="grid-item col-four">
        <div className="vision-section">
          <h2>{t('Our Vision')}</h2>
          <img src="../../images/images/vision-nextrust.jpg" alt="Vision" />

          <p>{t('To become the global standard for trust in information, ensuring every piece of data shared across platforms is verified, transparent, and reliable.')}</p>
        </div>
      </div>
      <div className="grid-item col-four">
        <div className="mission-section">
          <h2>{t('Our Mission')}</h2>
          <img src="../../images/images/mission-nextrust.jpg" alt="Mission" />

          <p>{t('To empower individuals, businesses, and governments worldwide by providing them with reliable and validated information, enabling informed decisions through advanced technology and robust verification processes.')}</p>
        </div>
      </div>
      <div className="grid-item col-four">
        <div className="goals-section">
          <h2>{t('Our Goals')}</h2>
          <img src="../../images/images/goal-nextrust.jpg" alt="Goals" />

          <p>{t('1. To integrate cutting-edge blockchain technology for seamless verification and traceability. 2. To foster a token-based economy that incentivizes accurate information sharing. 3. To expand globally, making trustworthy information accessible to everyone.')}</p>
        </div>
      </div>
    </div>
    
  </div>
</div>

<div className="problem-solution-section">
  <div className="content-section-homepage">
    <div className="grid-layout grid-one">
            <div className="grid-content-item center">
        <h1>{t('Our Commitment')}</h1>
        <p>{t('At NexTrust, we are dedicated to enhancing information integrity worldwide. By providing clear insights into the origins and validation processes of the data, we aim to build a more informed and trustworthy information ecosystem.')}</p>
      </div>
    </div>
   </div>
</div>

      <div className="content-section-homepage">
        <div className="grid-content-item col-six center">
          <h1>{t('Ready to build the Future of Trust with us?')}</h1>
        </div>
        <div className="grid-content-item col-six center">
          <Link to="/register"><button className="action">{t('Support our Mission')}</button></Link>
        </div>
      </div>

<div className="presale-section">
  <div className="content-section-homepage">
    <div className="grid-layout">
      <div className="grid-content-item col-six center">
        <h1>{t('Join the NexTrust Presale')}</h1>
        <p>{t('Secure your place in the future of trusted information. Participate in our presale to benefit from early rates and exclusive bonuses.')}</p>
      </div>
      <div className="grid-content-item col-six">
        <img src="../../images/images/presale.jpg" alt="Presale" />
      </div>
    </div>
    <div className="grid-layout grid-three">
      <div className="grid-item center">
        <div className="presale-feature">
          <h2>{t('Exclusive Rates')}</h2>
          <p>{t('Benefit from reduced token prices before they are available on public exchanges.')}</p>
        </div>
      </div>
      <div className="grid-item col-three center">
        <div className="presale-feature">
          <h2>{t('Early Access')}</h2>
          <p>{t('Gain early access to the platform and start using its features ahead of the general release.')}</p>
        </div>
      </div>
      
      <div className="grid-item col-three center">
        <div className="presale-feature">
          <h2>{t('Long-term Value')}</h2>
          <p>{t('Invest in tokens that are projected to grow in value as the NexTrust platform expands.')}</p>
        </div>
      </div>
    </div>
 </div>
    </div>

<div className="presale-section">
  <div className="content-section-homepage">
    <div className="grid-layout center">
      <div className="grid-content-item col-six center">
        <Link to="/register"><button className="action big">{t('Become Part of the Future')}</button></Link>
<p><br></br></p>
        <p>{t('Limited spots available. Secure your participation today.')}</p>
      </div>
    </div>
  </div>
</div>







<div className="how-to-get-tokens-section">
  <div className="content-section-homepage">
    <div className="grid-layout grid-one">
      <div className="grid-content-item col-twelve center">
        <h1>{t('How to Acquire NXT Tokens')}</h1>
        <p>{t('Participating in the NexTrust token sale is straightforward. Follow these simple steps to secure your NXT tokens.')}</p>
      </div>
    </div>
    <div className="grid-layout grid-three">
      <div className="grid-item col-four center">
        <div className="step">
          <h2>{t('Step 1: Register & Verify')}</h2>
          <img src="../../images/images/register.jpg" alt="Register and Verify" />
          <p>{t('Create your NexTrust account and complete the verification process to ensure a secure and compliant transaction environment.')}</p>
        </div>
      </div>
      <div className="grid-item col-four center">
        <div className="step">
          <h2>{t('Step 2: Add Wallet Address')}</h2>
          <img src="../../images/images/wallet_address.jpg" alt="Add Wallet Address" />
          <p>{t('Link your digital wallet by adding your wallet address. This will be used to receive and store your NXT tokens safely.')}</p>
        </div>
      </div>
      <div className="grid-item col-four center">
        <div className="step">
          <h2>{t('Step 3: Reserve & Pay')}</h2>
          <img src="../../images/images/pay-tokens.jpg" alt="Purchase Tokens" />
          <p>{t('Reserve your NXT tokens and complete the purchase either by paying directly through your wallet using MetaMask or by other payment methods provided.')}</p>
        </div>
      </div>
    </div>
    <div className="grid-layout center">
      <div className="grid-content-item col-six center">
<p><br></br></p>
<p><br></br></p>
        <Link to="/register"><button className="action big">{t('Get Your Tokens Now')}</button></Link>
<p><br></br></p>
        <p>{t('Get started today and be a part of the future of trusted information.')}</p>
      </div>
    </div>
  </div>
</div>



    </>
  );
}

export default NexTrustStartingPage;
