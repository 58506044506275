import React, { useState, useEffect } from 'react';

function NexTrustBackground() {
  const [backgroundImage, setBackgroundImage] = useState('');
  const [offsetY, setOffsetY] = useState(0);

  useEffect(() => {
    const totalImages = 7;
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const imagePath = `${process.env.PUBLIC_URL}/images/backgrounds/nextrust${randomIndex}.jpg`;
    setBackgroundImage(imagePath);
  }, []);

  return (
    <>
          <div className="app-background" style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: `50% ${offsetY * 0.5}px`, 
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed'
      }}>
       
      </div>

    </>
  );
}

export default NexTrustBackground;
