import axios from 'axios';

const baseURL = process.env.REACT_APP_API_BASE_URL || 'https://www.nextrust.app';
const axiosInstance = axios.create({ baseURL });

axiosInstance.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
  }
  return config;
}, error => {
  console.error("Request configuration failed:", error);
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    console.error("API response error:", error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
