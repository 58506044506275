import React, { useState, useEffect } from 'react';
import { useNavigate, Routes, Route, Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

function NexTrustRoadmapPage() {
  const [backgroundImage, setBackgroundImage] = useState('');
  const [offsetY, setOffsetY] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const totalImages = 7;
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const imagePath = `${process.env.PUBLIC_URL}/images/backgrounds/nextrust${randomIndex}.jpg`;
    setBackgroundImage(imagePath);

    const handleScroll = () => setOffsetY(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <div className="nextrust-other-page" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="overlay-content" style={{ top: `${offsetY * 0.3}px` }}>
         <h1>{t('Our Development Phases')}</h1>
<p>{t('Explore the step-by-step journey of NexTrust from inception to global expansion.')}</p>
             </div>
      </div>


 <div className="development-phases-section">
  <div className="content-section-homepage">
    <div className="grid-layout">
      <div className="grid-content-item center">
         <h1>{t('Your Journey Through Our Innovation')}</h1>
        <p>{t('Welcome to our roadmap! Discover the strategic phases of development that outline our path from conception to launch and beyond. Each stage is designed to enhance our offerings and engage our community, propelling us towards achieving a leading edge in the market.')}</p>
     
      </div>
    </div>
</div>

  <div className="content-section-homepage">
    <div className="grid-layout grid-three">
          <div className="grid-item">
        <div className="phase">
          <h1>{t('Phase 1: Launch and Initial Development')}</h1>
        </div>
      </div>
<div className="grid-item">
        <div className="phase">
          <p>{t('Kickstarting our journey with an Initial Coin Offering (ICO), focusing on foundational development, compliance, and initial market engagement. This phase lays the groundwork for our core product while also enhancing our market understanding through direct user feedback.')}</p>
        </div>
      </div>
 <div className="grid-item">
            <img src="../../images/images/phase1.jpg" alt="Phase 1 of NexTrust Launch" />
          </div>

</div>
</div>
<div className="content-section-homepage">
    <div className="grid-layout grid-three">
<div className="grid-item">
        <div className="phase">
          <h1>{t('Phase 2: MVP Launch and Market Engagement')}</h1>
                </div>
      </div>
 <div className="grid-item">
            <img src="../../images/images/phase2.jpg" alt="Phase 2 of NexTrust Launch" />
          </div>
          <div className="grid-item">
        <div className="phase">
          <p>{t('Launching our Minimum Viable Product (MVP) to the market to initiate user interaction and feedback loops. This phase is crucial for refining our offerings and expanding our business development efforts across strategic markets.')}</p>
        </div>
      </div>

    </div>
</div>
  <div className="content-section-homepage">
    <div className="grid-layout grid-three">
 <div className="grid-item">
            <img src="../../images/images/phase3.jpg" alt="Phase 3 of NexTrust Launch" />
          </div>
          <div className="grid-item">
        <div className="phase">
          <h1>{t('Phase 3: Service Expansion and Integration')}</h1>
        </div>
      </div>
<div className="grid-item">
        <div className="phase">
          <p>{t('Expanding our services and integrating enhanced functionalities into our platform. This phase focuses on scaling our solutions to meet the needs of a broader customer base and integrating user feedback into product enhancements.')}</p>
        </div>
      </div>
</div>
</div>
  <div className="content-section-homepage">
    <div className="grid-layout grid-three">
<div className="grid-item">
        <div className="phase">
          <h1>{t('Phase 4: Official Launch and Growth Scaling')}</h1>
        </div>
      </div>

<div className="grid-item">
            <img src="../../images/images/phase4.jpg" alt="Phase 4 of NexTrust Launch" />
          </div>

<div className="grid-item">
        <div className="phase">
          <p>{t('Our official platform launch will mark the beginning of a new stage in our growth, focusing on market expansion, customer acquisition, and leveraging the full potential of our technological and market insights to secure a prominent position in the industry.')}</p>
        </div>
      </div>
    </div>
</div>
    <div className="grid-layout center">
      <div className="grid-content-item col-six center">
        <Link to="/register"><button className="action big">{t('Join our Journey now')}</button></Link>
      </div>
    </div>
  </div>



    </>
  );
}

export default NexTrustRoadmapPage;
