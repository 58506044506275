import React, { useState, useEffect } from 'react';
import { useNavigate, Routes, Route, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function NexTrustTokenEconomyPage() {
  const [backgroundImage, setBackgroundImage] = useState('');
  const [offsetY, setOffsetY] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const totalImages = 7;
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const imagePath = `${process.env.PUBLIC_URL}/images/backgrounds/nextrust${randomIndex}.jpg`;
    setBackgroundImage(imagePath);

    const handleScroll = () => setOffsetY(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <div className="nextrust-other-page" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="overlay-content" style={{ top: `${offsetY * 0.3}px` }}>
          <h1>{t('The Token Economy')}</h1>
          <p>{t('NexTrust: Fueling Engagement with a Dynamic Token Economy.')}</p>
        </div>
      </div>

      <div className="content-section-homepage">
        <div className="grid-layout">
          <div className="grid-content-item center">
            <h1>{t('Revolutionize Your Operations with our Token Economy')}</h1>
            <p>{t('At NexTrust, we have developed a dynamic token economy designed to enhance user engagement and streamline platform transactions. Our NXT tokens reward contributions and facilitate a vibrant, interactive ecosystem.')}</p>
          </div>
        </div>
      </div>

     <div className="content-section-homepage">
  <div className="grid-layout grid-three">
    <div className="grid-item span-two">
      <h1>{t('Understanding Our Token System')}</h1>
      
      <h2>{t('Seamless Token Transactions')}</h2>
      <p>
        {t('NexTrust employs an innovative token exchange system, the NXT Token System, designed to simplify and secure transactions across our platform. Users earn NXT tokens by contributing valuable data, participating in community validations, or engaging with content. These tokens can then be utilized to access specialized services such as premium data sets, advanced analytical tools, or traded within the platform to other users or companies.')}
      </p>
      
      <h2>{t('User Benefits')}</h2>
      <p>
        {t('The NXT Token System directly benefits users by providing tangible rewards for their contributions and engagement. Tokens grant access to premium features and exclusive services, which are typically unavailable to non-token holders. This not only enhances user interaction but also ensures that active participants are rewarded with access to more valuable resources and capabilities, further motivating quality contributions and sustained engagement.')}
      </p>
      
      <h2>{t('Corporate Integration')}</h2>
      <p>
        {t('For companies, NXT tokens serve as a flexible and efficient method to pay for and utilize our API services or to request customized data validation services. This system eliminates the need for traditional payment methods, reducing transaction times and improving operational efficiency.')}
      </p>
      
      <h2>{t('How It Enhances Our Ecosystem')}</h2>
      <p>
        {t('The integration of NXT tokens into our platform enhances overall ecosystem health by fostering a balanced and dynamic environment where users and companies interact in a mutually beneficial manner. This creates a self-sustaining cycle of data validation, contribution, and consumption, which continuously improves the quality and reliability of information available, driving innovation and efficiency across industries.')}
      </p>
    </div>
    <div className="grid-item">
      <img src="../../images/images/token_economy.jpg" alt="Visualization of NexTrust Token Ecosystem" />
    </div>
  </div>
</div>

      <div className="content-section-homepage">
        <div className="grid-layout grid-two">
          <div className="grid-item col-twelve">
            <h3>{t('Market Stability and Growth')}</h3>
            <p>{t('To maintain market stability and ensure sufficient token supply, we strategically manage the release of NXT tokens. Our tokens are priced higher on our platform compared to public trading platforms to foster a robust trading environment.')}</p>
          </div>
          <div className="grid-item col-twelve">
            <h3>{t('Inclusive Economic Model')}</h3>
            <p>{t('Our token economy supports both the direct involvement of users and corporate partnerships. This inclusive approach ensures broad participation and ongoing growth within our ecosystem, contributing to the overarching goal of reliable data validation and accessibility.')}</p>
            
          </div>
        </div>
      </div>

      <div className="content-section-homepage">
        <div className="grid-layout grid-one center">
          <div className="grid-content-item">
             <Link to="/register"><button className="action big">{t('Join Our Token Ecosystem')}</button></Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default NexTrustTokenEconomyPage;
