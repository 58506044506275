import React, { createContext, useState, useContext, useEffect } from 'react';

const MessageContext = createContext();

export const useMessage = () => useContext(MessageContext);

export const MessageProvider = ({ children }) => {
  const [message, setMessageState] = useState('');

  const setMessage = (msg) => {
    setMessageState(msg);
    setTimeout(() => {
      setMessageState('');
    }, 5000);
  };

  return (
    <MessageContext.Provider value={{ message, setMessage }}>
      {children}
    </MessageContext.Provider>
  );
};
