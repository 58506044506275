import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../LanguageSwitcher';
import '../i18n';

function HomePageHeaderBar() {
    const [headerClass, setHeaderClass] = useState('homepage-header-bar');
    const [logoSrc, setLogoSrc] = useState("../../images/logo/logo-nextrust.png");
    const { t, i18n } = useTranslation();
    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('token') ? true : false);
	const [isMenuOpen, setMenuOpen] = useState(false); 
	const location = useLocation(); 
	
    useEffect(() => {
        const handleScroll = () => {
            const show = window.scrollY > 0;
            setHeaderClass(show ? 'homepage-header-bar scrolled' : 'homepage-header-bar');
            setLogoSrc(show ? "../../images/logo/logo-nextrust.png" : "../../images/logo/logo-nextrust.png");
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
	
	
 useEffect(() => {
        setMenuOpen(false);
    }, [location]);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };


    return (
		<>
	 <div className="homepage-mobile-header">
            <div className="homepage-header">
		<div className="mobile-logo">
                    <NavLink to="/"><img src={logoSrc} alt={t('nextrust_logo')}></img></NavLink>
                </div>
                <div className="menu-button" onClick={toggleMenu}>
                    {isMenuOpen ? 'Close' : 'Menu'}
                </div>
                {isMenuOpen && ( 
		<>
		  
                    <div className="full-screen-menu">
                        <NavLink to="/product">{t('product')}</NavLink>
                        <NavLink to="/business-model">{t('business_model')}</NavLink>
                        <NavLink to="/roadmap">{t('roadmap')}</NavLink>
                        <NavLink to="/token-economy">{t('token')}</NavLink>
                        <NavLink to="/contact">{t('contact')}</NavLink>
<br></br>
<br></br>
                        <NavLink to="/login">Login</NavLink>
 <NavLink to="/register">Register</NavLink>
<br></br>
<br></br>
                <LanguageSwitcher currentLanguage={localStorage.getItem('language')} changeLanguage={(language) => i18n.changeLanguage(language)} />

                    </div>

</>
                )}
            </div>
        </div>



        <div className={headerClass}>
            <div className="homepage-header">
                <div className="homepage-logo">
                    <NavLink to="/"><img src={logoSrc} alt={t('nextrust_logo')}></img></NavLink>
                </div>
                <div className="homepage-button">
                    <NavLink to="/product">{t('product')}</NavLink>
                    <NavLink to="/business-model">{t('business_model')}</NavLink>
                    <NavLink to="/roadmap">{t('roadmap')}</NavLink>
					<NavLink to="/token-economy">{t('token')}</NavLink>
					<NavLink to="/contact">{t('contact')}</NavLink>
                </div>
                <div className="homepage-language-switcher"><LanguageSwitcher currentLanguage={localStorage.getItem('language')} changeLanguage={(language) => i18n.changeLanguage(language)} /></div>
                {isLoggedIn ? (
					<>
                    <div className="homepage-header-menu">
                        <NavLink to="/nextrust/"><img src="../../images/icons/user.png" alt="User - NexTrust" /></NavLink>
                    </div>
					<div className="homepage-header-menu-blue">
                        <NavLink to="/nextrust/"><img src="../../images/icons/user_blue.png" alt="User - NexTrust" /></NavLink>
                    </div>
					</>
                ) : (
					<>
                    
					<div className="homepage-header-menu">
                        <NavLink to="/login"><img src="../../images/icons/user.png" alt="User - NexTrust" /></NavLink>
                    </div>
					<div className="homepage-header-menu-blue">
                        <NavLink to="/login"><img src="../../images/icons/user_blue.png" alt="User - NexTrust" /></NavLink>
                    </div>
					</>
                )}
            </div>
        </div>

</>
    );
}

export default HomePageHeaderBar;
