import React, { useState, useEffect } from 'react';
import axios from '../axios'; 
import { useNavigate } from 'react-router-dom';
import { useMessage } from '../MessageContext';
import { useTranslation } from 'react-i18next';

const ConfirmEmailPage = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordRequirements, setPasswordRequirements] = useState({
    minLength: false,
    upperCase: false,
    lowerCase: false,
    number: false,
    symbol: false,
    passwordsMatch: false
  });
  const [showError, setShowError] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [offsetY, setOffsetY] = useState(0);
  const navigate = useNavigate();
  const { setMessage } = useMessage();
  const { t } = useTranslation();

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');

  useEffect(() => {
    const totalImages = 7;
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const imagePath = `${process.env.PUBLIC_URL}/images/backgrounds/nextrust${randomIndex}.jpg`;
    setBackgroundImage(imagePath);

    const handleScroll = () => setOffsetY(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const validatePassword = (password, confirmPassword) => {
    const requirements = {
      minLength: password.length >= 8,
      upperCase: /[A-Z]/.test(password),
      lowerCase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      symbol: /[^A-Za-z0-9]/.test(password),
      passwordsMatch: password === confirmPassword
    };
    setPasswordRequirements(requirements);
    return Object.values(requirements).every(Boolean);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setMessage(false);
    if (!validatePassword(password, confirmPassword)) {
setMessage(t('Please ensure your password meets all the requirements listed below.'));
      setMessage(true);
      return;
    }
    setMessage('Verifying your email...');
    try {
      const res = await axios.post('/users/verify', { token, password });
      setMessage(t('Your email has been confirmed! Redirecting to login...'));
      navigate('/login');
    } catch (err) {
      if (err.response) {
setMessage(t('Failed to verify email:') + ` ${err.response.data.message}`);
      } else if (err.request) {
        setMessage(t('Failed to verify email: No response from server. Please check your network connection.'));
      } else {
setMessage(t('Failed to verify email: An unexpected error occurred.'));
      }
    }
  };

  const formatRequirement = (flag, text) => flag ? <span style={{ color: '#081a44' }}>{text}</span> : <span style={{ color: 'red' }}>{text}</span>;

  return (
    <div className="nextrust-starting-page" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="overlay-content" style={{ top: `${offsetY * 0.3}px` }}>
        <form onSubmit={handleSubmit}>
          <h2>{t('Confirm Your Email and Set Your Password')}</h2>
          <div className="form-group">
            <label>{t('password')}</label>
            <input
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                validatePassword(e.target.value, confirmPassword);
              }}
              required
            />
          </div>
          <div className="form-group">
            <label>{t('confirm_password')}</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                validatePassword(password, e.target.value);
              }}
              required
            />
          </div>
       
          <div className="form-group">
            <p>{formatRequirement(passwordRequirements.minLength, t('At least 8 characters'))}</p>
<p>{formatRequirement(passwordRequirements.upperCase, t('An uppercase letter'))}</p>
<p>{formatRequirement(passwordRequirements.lowerCase, t('A lowercase letter'))}</p>
<p>{formatRequirement(passwordRequirements.number, t('A number'))}</p>
<p>{formatRequirement(passwordRequirements.symbol, t('A special character'))}</p>
<p>{formatRequirement(passwordRequirements.passwordsMatch, t('Passwords match'))}</p>
        </div>
          <button type="submit" disabled={!Object.values(passwordRequirements).every(Boolean)}>{t('verify')}</button>
        </form>
      </div>
    </div>
  );
};

export default ConfirmEmailPage;
